/* Primary Colors */
@CAPGEMINI_BLUE: #0070ad;
@VIBRANT_BLUE  : #12abdb;
@DEEP_PURPLE   : #2b0a3d;
@DARK_GREY     : #272936;
@DEFAULT_GREY  : #e5e5e5;
@WHITE         : #ffffff;
@FOREGROUNDTD  : #3b3b3b;

@grey1: rgba(@DARK_GREY, 20%);

/* Cool Colors GREEN */
@COOL_GREEN1: #57cf80;
@COOL_GREEN2: #33b569;
@COOL_GREEN3: #2ea657;
@COOL_GREEN4: #178c3d;
@COOL_GREEN5: #178036;
@COOL_GREEN6: #b0e89c;

/* Cool Colors TEAL */
@COOL_TEAL1: #00e6e3;
@COOL_TEAL2: #00d5d0;
@COOL_TEAL3: #00bfbf;
@COOL_TEAL4: #00929b;
@COOL_TEAL5: #007d74;

/* Cool Colors PEACOCK */
@COOL_PEACOCK1: #00e0cb;
@COOL_PEACOCK2: #00b2a2;
@COOL_PEACOCK3: #0f878a;
@COOL_PEACOCK4: #0f6a73;
@COOL_PEACOCK5: #0f434a;

/* Cool Colors SAPPHIRE */
@COOL_SAPPHIRE1: #338091;
@COOL_SAPPHIRE2: #336b7d;
@COOL_SAPPHIRE3: #14596b;
@COOL_SAPPHIRE4: #214554;
@COOL_SAPPHIRE5: #173340;

/* Warm Colors VIOLET */
@WARM_VIOLET1: #e557ad;
@WARM_VIOLET2: #d13a8c;
@WARM_VIOLET3: #ba2980;
@WARM_VIOLET4: #a12980;
@WARM_VIOLET5: #811b6f;

/* Warm Colors YELLOW */
@WARM_YELLOW1: #ffda80;
@WARM_YELLOW2: #ffd068;
@WARM_YELLOW3: #ffb24a;
@WARM_YELLOW4: #ff9c29;
@WARM_YELLOW5: #ff8e12;

/* Warm Colors VELVET */
@WARM_VELVET1: #9e4780;
@WARM_VELVET2: #802b73;
@WARM_VELVET3: #750d5c;
@WARM_VELVET4: #590a42;
@WARM_VELVET5: #42142e;

/* Warm Colors RED */
@WARM_RED1: #ff5770;
@WARM_RED2: #ff455e;
@WARM_RED3: #ff304d;
@WARM_RED4: #e30021;
@WARM_RED5: #a6001a;
@WARM_RED6: #ffecee;

@colorWhite                   : @WHITE;
@colorGreyMid                 : #d1d1d1;
@colorLink                    : @CAPGEMINI_BLUE;
@colorGreyLight               : #f7f7f7;
@colorBlack                   : #000000;
@colorTextContent             : @DARK_GREY;
@colorPanelHead               : @DEEP_PURPLE;
@colorBranding                : #eb5f1e;
@colorSecondary               : @DEEP_PURPLE;
@colorError                   : @WARM_RED4;
@colorErrorGrid               : @WARM_RED6;
@colorWarning                 : @WARM_YELLOW4;
@colorInfoText                : #7e7e7e;
@colorPrimary                 : @CAPGEMINI_BLUE;
@colorPageTitle               : @DEEP_PURPLE;
@colorBorders                 : #d1d1d1;
@colorFilters                 : #f8f8ff;
@colorGreyDark                : #ececec;
@colorButtonText              : #15011d;
@colorInteractionDark         : #15011d;
@colorTableBackgroundPrimary  : #d9d9d9;
@colorTableBackgroundSecondary: #f2f2f2;
@colorBtnHover                : #00e6e3;
@colorLinks                   : #0070ac;
@subnavCol                    : rgb(0 112 173 / 10%);

// Addons
@colorSuccess     : #178036;
@colorGreyMild    : #dadada;
@colorGreyCool    : #e5e5e5;
@colorGreyWarm    : #e7e7e7;
@colorDisabledText: #6b6b6b;
// @colorGreyDark: #ababab;

//Nav Bar
@mainHeader   : @WHITE; // Appears 6 times
@NavstartColor: @CAPGEMINI_BLUE;
@NavendColor  : @CAPGEMINI_BLUE;

// Widget Style Default
@WidgetstartColor: @CAPGEMINI_BLUE;
@WidgetendColor  : @DEEP_PURPLE;

/**
 * @font-face LESS Mixin
 * use: .font-face(
 *   @font-family, // name
 *   @file-path,   // absolute/relative URL to font files
 *   @font-weight, // light/normal/bold/inherit | 300/400/700
 *   @font-style   // italic/normal/inherit
 * )
 */
.font-face(@font-family, @file-path, @font-weight, @font-style) {
  @font-face {
    font-family: @font-family;
    src        : url("@{file-path}.eot");
    src        : url("@{file-path}.eot?#iefix") format("embedded-opentype"),
    url("@{file-path}.woff") format("woff"),
    url("@{file-path}.ttf") format("truetype"),
    url("@{file-path}.svg#@{file-path}") format("svg");
    font-weight : @font-weight;
    font-style  : @font-style;
    font-display: swap;
  }
}

/**
   * font LESS Mixin
   * use: .font(
   *   @font-family, // name
   *   @file-size,   // any unit/inherit
   *   @font-weight, // light/normal/bold/inherit | 300/400/700
   *   @font-style   // italic/normal/inherit
   * )
   */

.font-face(Ubuntu-Light, "../fonts/Ubuntu-Light", 300, normal);
.font-face(Ubuntu-Regular, "../fonts/Ubuntu-Regular", 400, normal);
.font-face(Ubuntu-Medium, "../fonts/Ubuntu-Medium", 500, normal);
.font-face(Ubuntu-Bold, "../fonts/Ubuntu-Bold", 700, normal);

.font(@font-family, @font-size, @font-weight, @font-style) {
  font-family: @font-family;
  font-size  : @font-size;
  font-weight: @font-weight;
  font-style : @font-style;
}

@thin: -apple-system, BlinkMacSystemFont, Ubuntu-Light, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@regular: -apple-system, BlinkMacSystemFont, Ubuntu-Regular, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@medium: -apple-system, BlinkMacSystemFont, Ubuntu-Medium, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@bold: -apple-system, BlinkMacSystemFont, Ubuntu-Bold, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;

.FontThin(@font-size) {
  .font(@thin, @font-size, 300, normal);
}

.FontRegular(@font-size) {
  .font(@regular, @font-size, 400, normal);
}

.FontMedium(@font-size) {
  .font(@medium, @font-size, 500, normal);
}

.FontBold(@font-size) {
  .font(@bold, @font-size, 700, normal);
}

//Border Radius
.borderRadius (@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius   : @radius;
  -ms-border-radius    : @radius;
  -o-border-radius     : @radius;
  border-radius        : @radius;
}

//Shadow
.box-shadow (@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow   : @shadow;
  box-shadow        : @shadow;
}

// Gradient
.gradient (@startColor, @endColor) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(@startColor),
      to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

// Transitions
.transitions(@effects) {
  -webkit-transition: @effects;
  -moz-transition   : @effects;
  -ms-transition    : @effects;
  -o-transition     : @effects;
  transition        : @effects;
}

// Transform
@transformX: translateX(-50%);
@transformY: translateY(-50%);

.transform(@transform) {
  -webkit-transform: @transform;
  -moz-transform   : @transform;
  -ms-transform    : @transform;
  -o-transform     : @transform;
  transform        : @transform;
}

.ellipsis {
  white-space  : nowrap;
  text-overflow: ellipsis;
  overflow     : hidden;
}

.alignVertical {
  position: relative;
  .transform(translateY(50%));
}

.alignHorizontal {
  position: relative;
  .transform(translateX(50%));
}

.overflowY {
  overflow-x     : hidden;
  overflow-y     : auto;
  scroll-behavior: smooth;
}

.text-primary {
  color: @CAPGEMINI_BLUE  !important;
}

.text-info {
  color: @VIBRANT_BLUE  !important;
}

.text-success {
  color: @colorSuccess  !important;
}

.text-warning {
  color: @WARM_YELLOW4  !important;
}

.text-danger {
  color: @WARM_RED4  !important;
}

.bg-primary {
  background-color: @CAPGEMINI_BLUE  !important;
  color           : @WHITE;
}

.bg-info {
  background-color: @VIBRANT_BLUE  !important;
}

.bg-success {
  background-color: @colorSuccess  !important;
  color           : @WHITE;
}

.bg-warning {
  background-color: @WARM_YELLOW4  !important;
  color           : @colorTextContent;
}

.bg-danger {
  background-color: @WARM_RED4  !important;
  color           : @WHITE;
}

.bgSecondary {
  background-color: @DEEP_PURPLE  !important;
}

.bgDarkGrey {
  background-color: @DARK_GREY  !important;
}

/*=============================================
=            Inline Borders            =
=============================================*/
.borderInline {
  .box-shadow(inset 0 0 0 1px @colorBorders);
}

.borderInlineLeft {
  .box-shadow(-1px 0 0 0 @colorGreyMid);
}

html,
body {
  height         : 100%;
  scroll-behavior: smooth;
}

body {
  font-family           : @regular;
  /*Font Vaiable*/
  font-size             : 0.875rem;
  /* Inherit from       :root and set to 14px */
  font-weight           : normal;
  text-rendering        : optimizeLegibility;
  text-rendering        : geometricPrecision;
  -moz-font-smoothing   : antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  color                 : @colorTextContent;
}

/** In Chrome we can't use the semilight weight of the local web font. Use web fonts instead. **/

body:not(*:root) {
  font-family: -apple-system, BlinkMacSystemFont, Ubuntu, "Segoe UI", Roboto,
    Oxygen, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /*font family Variable*/
}

a {
  text-decoration: none;
}

/*----------  Fonts Weight  ----------*/
.text-bold {
  .FontMedium(1rem);
}

.text-bold-grid {
  background-color: @WARM_YELLOW2 !important;
}



.text-normal {
  line-height: normal;
  .FontRegular(inherit);
}

/*=============================================
=            Main Nav Styles            =
=============================================*/
.navbar {
  background: @mainHeader  !important;
  border    : none;
  padding   : 0 0 0 1rem;
  min-height: 3.125rem;
}

.navbar-brand {
  .FontMedium(1.125rem);
  display    : flex;
  align-items: center;
}

.navbar-brand img {
  height: 50px;
  width : 128px;
}

.navbar-brand p {
  color: @colorBlack;
}

.navbar-brand span {
  font-size: 20px;
}

/* Main Navigation */

.navbar .nav-item {
  .transitions(all 0.3s ease-in-out);
}

.navbar .nav-item .nav-link {
  line-height: 3.125rem;
  padding    : 0 1rem;
  color      : @DEEP_PURPLE;
  text-shadow: none;
  .transitions(all 0.3s ease-in-out);
}

.navbar .nav-item .nav-link i {
  padding-left : 0.625rem;
  padding-right: 0.625rem;
}

.navbar .nav-item:hover {
  text-decoration: underline;
  color          : @CAPGEMINI_BLUE;
}

.navbar .nav-item:hover .nav-link {
  color: @CAPGEMINI_BLUE;
}

.navbar .dropdown-toggle::after {
  border: none;
}

.navbar-expand-md .navbar-nav .dropdown-menu,
.pageMenu .dropdown-menu {
  padding      : 0;
  border-radius: 0;
  border       : none;
  background   : @colorSecondary;
  margin       : 0;
  color        : @colorWhite;
}

.download-menu .dropdown-item {
  color: @colorBlack  !important;
}

.download-menu .dropdown-item:hover {
  color: @colorWhite  !important;
}

.navbar-nav>.dropdown>.dropdown-menu,
.pageMenu>.dropdown>.dropdown-menu {
  margin: 0;
}

.dropdown-menu li .dropdown-item,
.sub-menu li .dropdown-item,
.pageMenu .dropdown-item {
  color        : @colorWhite;
  font-size    : 0.813rem;
  padding      : 0.625rem 1rem;
  transition   : all 0.2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.pageMenu .dropdown-item {
  line-height: 1.188rem;
  .FontRegular(0.875rem);
}

.dropdown-menu>li:last-child>.dropdown-item {
  border-bottom: none;
}

.sub-menu {
  left: 100%;
  top : 0;
}

.navbar-expand-md .navbar-nav .dropdown-menu .dropdown-item:hover,
.pageMenu .dropdown-menu .dropdown-item:hover {
  background: @VIBRANT_BLUE;
  color     : @DEEP_PURPLE;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropstart .dropdown-menu {
  top         : 0;
  right       : 100%;
  left        : auto;
  margin-top  : 0;
  margin-right: 0.125rem;
}

.leftSidebar .nav-pills .nav-link {
  display    : flex !important;
  align-items: center;
  padding    : 1.5em;
}

/*----------  Nav Tabs  ----------*/
.nav.nav-tabs {
  height          : 3.125rem;
  background-color: @WHITE;
  border-bottom   : 0;
  text-align      : center;
}

.tab-set .nav.nav-tabs {
  height: 10.125rem !important;
}

.nav-tabs .nav-item {
  font-size  : 1rem;
  font-family: @medium;
  position   : relative;
  min-width  : 14rem;
}

/* .p2w-lite .nav.nav-tabs{
  height: 6.125rem !important;
} */

.p2w-lite .nav-tabs .nav-item {
  min-width: 8rem !important;
}

.nav-tabs .nav-item .nav-link {
  .FontRegular(1rem);
  height     : 3.125rem;
  color      : @colorTextContent;
  position   : relative;
  line-height: 2.063rem;
  border-top : 0;
  .borderRadius(0);
  .transitions(background-color 0.3s ease-in-out);
}

.nav-tabs .nav-item.dropdown:focus .nav-link.active,
.nav-tabs .nav-item.dropdown:hover .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active {
  background-color: @colorWhite;
  border-color    : @colorGreyMid @colorGreyMid @colorWhite;
  color           : @colorLink;
  border          : 0;
  margin-bottom   : 1px;
}

.ST-tabs .nav-link.active {
  color: @DARK_GREY  !important;
}

.nav-tabs .nav-item.dropdown:focus .nav-link.nav-click,
.nav-tabs .nav-item.dropdown:hover .nav-link.nav-click,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.nav-click:hover,
.nav-tabs .nav-link.nav-click:focus,
.nav-tabs .nav-link.nav-click {
  background-color: @colorWhite;
  border-color    : @colorGreyMid @colorGreyMid @colorWhite;
  color           : @colorLink;
  border          : 0;
  margin-bottom   : 1px;
}

.nav-tabs .nav-item.dropdown .nav-link.active {
  line-height: 1rem;
  height     : 3.125rem;
}

.nav-tabs .dropdown-toggle::after {
  display: block;
  margin : 0;
}

.nav-tabs .nav-item.dropdown:focus .nav-link,
.nav-tabs .nav-item.dropdown:hover .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color    : transparent transparent @colorGreyMid;
  background-color: @colorGreyMid;
  color           : @colorBlack;
}

.ST-tabs .nav-link.nav-click:after,
.ST-tabs .nav-link.nav-click:before {
  content        : "";
  position       : absolute;
  width          : 0;
  height         : 0;
  margin         : 0;
  width          : 100%;
  // border-left: 8px solid transparent !important;
  // border-right: 8px solid transparent !important;
  border-bottom  : 3px solid @CAPGEMINI_BLUE  !important;
  bottom         : 0;
  left           : 50%;
  .transform(@transformX);
  z-index: 10;
}

.OP-tabs .nav-tabs .nav-link.active:after,
.OP-tabs .nav-tabs .nav-link.active:before {
  content        : "";
  position       : absolute;
  width          : 0;
  height         : 0;
  margin         : 0;
  width          : 100%;
  // border-left: 8px solid transparent !important;
  // border-right: 8px solid transparent !important;
  border-bottom  : 3px solid @CAPGEMINI_BLUE  !important;
  bottom         : 0;
  left           : 50%;
  .transform(@transformX);
  z-index: 10;
}

.nav-tabs .nav-link.active:before {
  // border-left: 10px solid transparent !important;
  // border-right: 10px solid transparent !important;
  border-bottom: 3px solid @CAPGEMINI_BLUE  !important;
}

.nav-tabs .nav-link.ST-tabs:before {
  // border-left: 10px solid transparent !important;
  // border-right: 10px solid transparent !important;
  border-bottom: 3px solid @CAPGEMINI_BLUE  !important;
}

.nav-tabs .nav-item.dropdown:hover .nav-link.active:after {
  border-bottom: 3px solid @CAPGEMINI_BLUE  !important;
}

.nav-tabs .nav-item.dropdown:hover .nav-link.active::before {
  border-bottom: 3px solid @CAPGEMINI_BLUE  !important;
}

.nav-tabs .nav-link.dropdown-toggle::after {
  border-top: 0;
}

.nav-tabs .dropdown-menu {
  background: @WHITE;
  border    : none;
  width     : 100%;
  padding   : 0.625rem;
  font-size : 0.875rem;
  z-index   : 11;
  .borderRadius(0);
  .box-shadow(0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15));
}

.dropdown-menu,
.inlineDropdown .formDropdown {
  background: @WHITE;
  padding   : 0;
  font-size : 0.875rem;
  z-index   : 11;
  .borderRadius(0);
  .box-shadow(0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15));
}

.nav-tabs .dropdown-item,
.inlineDropdown .dropdown-item {
  .FontRegular(inherit);
  color      : @DARK_GREY;
  padding    : 0 1rem;
  line-height: 2.25rem;
  .transitions(all 0.3s ease-in-out);
}

.dropdown-menu>.dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: @DARK_GREY;
  color           : @WHITE;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: @DARK_GREY;
  color           : @WHITE;
}

.formDropdown .dropdown-menu {
  padding    : 1rem;
  line-height: normal;
  min-width  : 16.625rem;
  width      : max-content;
  top        : calc(~"2.75rem - 0.5px");
  //top: calc("2.75rem - 0.5px");

  right      : -50%;
}

.formDropdown .dropdown-menu .form-check {
  margin-bottom: 0.5rem;
}

.formDropdown .dropdown-menu .form-group {
  margin-bottom: 0;
}

.tabpageName {
  display   : block;
  opacity   : 0;
  color     : @colorTextContent;
  font-size : 0.75rem;
  width     : 100%;
  text-align: center;
  position  : absolute;
  bottom    : 5rem;
  .transitions(all 0.3s ease-in-out);
}

.nav-tabs .dropdown .nav-link.active+.tabpageName {
  opacity: 1;
  bottom : 0.5rem;
}

#tabMain+.tab-content {
  background     : @DEFAULT_GREY url("../images/Capgemini-Line.svg") no-repeat;
  background-size: cover;
  .box-shadow(inset 0 10px 10px -10px rgba(0, 0, 0, 0.15));
}

.tab-content:not(.childTab) {
  background     : #ffffff;
  background-size: 100%;
  position       : relative;
  height         : calc(~"100% - 3.125rem");
  border         : 1px solid @colorBorders;
}

.userData+.contentScroll .tab-content {
  background: none;
  .box-shadow(none);
}

.subTabs ul.nav.nav-tabs {
  border       : 1px solid @colorGreyMid;
  border-bottom: 0;
}

.subTabs .nav-item:first-child a.nav-link.active {
  border-left-color: transparent;
}

.subTabs .tab-content {
  height: calc(~"100% - 6.8rem");
}

.subTabs .tab-content.subFilters {
  height: calc(~"100% - 3.062rem");
}

.modal .tab-content {
  height: auto;
}

.subTabs .tab-content .contentScroll {
  height: 100%;
}

.subTabs .tab-content.subFilters .contentScroll {
  height: calc(~"100% - 3.625rem");
}

.tab-content>.active {
  height         : 100%;
  position       : relative;
  overflow-y     : auto;
  scroll-behavior: smooth;
  overflow-x     : hidden;
}


.p2w-lite .tab-content>.active {
  height         : 100%;
  position       : relative;
  overflow-y     : auto;
  scroll-behavior: smooth;
  overflow-x     : hidden;
}

.servicetypes_tab .tab-content>.active {
  overflow: hidden !important;
}

.subTabs .tab-content>.active {
  .borderBox;
  border-top     : 0;
  overflow-y     : auto;
  scroll-behavior: smooth;
}

.nav-pills .nav-link {
  .borderRadius(0);
}

/* User Profile */

.userProfile .nav-item .nav-link {
  padding: 0 1rem;
}

.userProfile .nav-item:hover {
  text-decoration: underline;
  color          : @CAPGEMINI_BLUE;
}

.userProfile .nav-item:hover .nav-link {
  color: @CAPGEMINI_BLUE;
}

.userProfile .dropdown-menu {
  right: 0;
  left : inherit;
}

/* #Breadcrumb */

.breadcrumb {
  padding: 1rem;
  .borderRadius(0);
  background-color: @colorGreyDark;
}

.breadcrumb-item {
  .FontRegular(0.875rem);
  line-height: 1.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
}

.breadcrumb-item.active a {
  color: @DARK_GREY;
}

/*=============================================
=            Icon Tab            =
=============================================*/
.iconTab {
  list-style      : none;
  padding         : 0;
  margin          : 0;
  height          : 100%;
  background-color: @colorGreyLight;
  .box-shadow(inset 0 0 0 1px @colorBorders);
}

.iconTab li {
  width      : 3.125rem;
  height     : 3.125rem;
  line-height: 3.125rem;
}

.iconTab li a {
  font-family: @medium;
  font-size  : 1rem;
  display    : block;
  text-align : center;
  color      : @colorTextContent;
  position   : relative;
  .box-shadow(inset 0 -1px 0 0 @colorBorders);
}

.iconTab li a:hover {
  background-color: @colorGreyMid;
  color           : @colorBlack;
}

.iconTab li a:after {
  background-color: @colorGreyLight;
  content         : attr(data-title);
  position        : absolute;
  left            : -1000%;
  bottom          : 0;
  width           : 12rem;
  height          : calc(~"100% + 1px");
  text-align      : left;
  padding         : 0 1rem;
  z-index         : 1;
  .box-shadow(inset 0 0 0 1px @colorBorders);
  .transitions(left 0.4s ease-in-out);
}

.iconTab li a.active:before {
  content      : "";
  position     : absolute;
  width        : 0px;
  height       : 0px;
  border-top   : 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right : 8px solid @colorWhite;
  right        : -1px;
  top          : 50%;
  z-index      : 9;
  .transform(@transformY);
}

.iconTab li:first-child a:after {
  height: 100%;
}

.iconTab li a:hover:after {
  left: 100%;
}

.iconTab li a.active {
  background-color: @colorSecondary;
  color           : @colorWhite;
}

.iconTab li a.active:after {
  color: @colorBlack;
}

/*----------  Icon Links  ----------*/

a {
  color: @colorLink;
  .transitions(all 0.3s ease-in-out);
}

a:hover {
  text-decoration: none;
}

ul.iconLink {
  padding   : 0;
  margin    : 0;
  list-style: none;
}

ul.iconLink li {
  float: left;
}

ul.iconLink li a {
  color: @colorLink;
  .FontMedium(inherit);
  padding: 0 15px;
}

ul.iconLink li~li a {
  .box-shadow(inset 1px 0 0 0 @colorBorders);
}

ul.iconLink li a:hover {
  color: @colorBlack;
}

ul.iconLink li a i {
  padding-right: 0.625rem;
}

/*----------  Quick Action  ----------*/
.quickAction .list-group-item {
  font-family: @medium;
  padding    : 0;
  .borderRadius(0);
}

.quickAction .list-group-item:not(:last-child) {
  margin-bottom: 0.625rem;
}

.quickAction .list-group-item a {
  padding: 0.625rem;
  color  : @colorTextContent;
}

.quickAction .list-group-item a:hover {
  .box-shadow(0 0 0 1px @colorLink, 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.15);
  );
}

.quickAction .list-group-item a.active {
  background-color: lighten(@colorLink, 60%);
}

/* Avatar */

.img-avatar {
  display      : inline-block !important;
  width        : 64px;
  height       : 64px;
  border-radius: 50%;
}

.img-avatar.img-avatar16 {
  width : 16px;
  height: 16px;
}

.img-avatar.img-avatar32 {
  width : 32px;
  height: 32px;
}

.img-avatar.img-avatar48 {
  width : 48px;
  height: 48px;
}

.img-avatar.img-avatar96 {
  width : 96px;
  height: 96px;
}

.img-avatar.img-avatar128 {
  width : 128px;
  height: 128px;
}

.img-avatar-thumb {
  .box-shadow(0 0 0 2px @colorWhite);
  margin: 0 5px 5px;
}

/*=====  End of Main Nav Styles  ======*/

/*=============================================
=            Body Section            =
=============================================*/
.bodyContainer {
  position   : relative;
  padding-top: 0;
  height     : 100%;
}

#sideNav-section .bg-dark {
  background-color: #2b0a3d !important;
  padding         : 0 !important;
}

#sideNav-section .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: @CAPGEMINI_BLUE;
}

.navbar+.bodyContainer {
  padding-top: 60px;
  height     : calc(~"100% - 60px");
}

.contentScroll {
  height         : calc(~"100% - 3.625rem");
  overflow       : auto;
  scroll-behavior: smooth;
}

.p2w-lite.contentScroll {
  height: calc(100% - 0rem) !important;
}

.contentScroll.full {
  height: 100%;
}

.customForm+.contentScroll {
  height: calc(~"100% - 5.875rem");
}

.breadcrumbSection+.contentScroll {
  height          : calc(~"100% - 3.8125rem");
  overflow        : auto;
  scroll-behavior : smooth;
  background-color: @colorGreyLight;
  position        : absolute;
  width           : 100%;
}

.breadcrumbSection+.contentScroll.myDealCard {
  overflow-y: hidden;
  overflow-x: hidden;
}

.flex-fill.bg-white {
  width: calc(~"100% - 300px");
}

.footer {
  background-color: @colorGreyDark;
}

.footer a {
  color: #000000;
}

.footer a:hover {
  color          : @CAPGEMINI_BLUE;
  text-decoration: underline;
}

.footer img {
  width : 150px;
  height: 60px;
}

.footer {
  padding: 0 1rem;
}

.footer-links a~a {
  padding-left: 1rem;
}

/*=============================================
=            Left Sidebar            =
=============================================*/

.leftSidebar {
  background-color: #2b0a3d;
}

.leftSidebar ul li a.p-1.text-decoration-none .hamburger-icon {
  display: none;
}

.leftSidebar ul li a.p-1.text-decoration-none.collapsed .hamburger-icon {
  display: inline-block;
}

.leftSidebar ul li a.collapsed .cross-icon {
  display: none;
}

.leftSidebar ul li a.nav-link.active {
  background: #0070ad;
}

.leftSidebar ul li>a.active {
  background-color: #0070ad;

}

.leftSidebar ul li a span {
  color                     : #ffffff;
  width                     : 148px;
  margin-left               : 0.5em;
  transition-property       : width;
  transition-duration       : 1s;
  transition-timing-function: ease-in-out;
  transition-delay          : .1s;
}

.leftSidebar ul li a {
  padding: 8px 21px;
}

/* Sidebar css */
.sidebar-container {
  background-color: @DEEP_PURPLE;
  .transitions(all 0.3s ease-in-out);
  border-top: 1px solid #2b0a3d;
}

.sidebar-container .list-group {
  .transitions(all 0.3s ease-in-out);
  .borderRadius(0);
  width    : auto;
  min-width: 18rem !important;
  height   : 49rem;
}

.close-menu {
  text-indent: -99999px;
  background : url("../images/cross.svg") no-repeat center center;
  display    : block;
  overflow   : hidden;
  width      : 56px;
  height     : 56px;
  .transitions(all 0.3s ease-in-out);
  .transform(rotate(0deg));
}

.close-menu.collapsed {
  background: url("../images/hamburger.svg") no-repeat center center;
  .transform(rotate(-180deg));
}

.close-menu.collapsed+.list-group {
  width   : 3.5rem;
  overflow: hidden;
}

/*SIDEBAR STYLES STARTS*/
.list-group-item i {
  display   : inline-block;
  width     : 32px;
  height    : 32px;
  background: green;
}

div#sidebar {
  background-color: #2b0a3d;
}

/*SIDEBAR STYLES STARTS*/
.list-group-item i {
  display   : inline-block;
  width     : 32px;
  height    : 32px;
  background: green;
}

div#sidebar {
  background-color: #2b0a3d;
}

.sidebar-container .list-group-item {
  background-color: transparent;
  border          : 0;
  color           : @WHITE;
  padding         : 0.75rem;
  .borderRadius(0);
}

.sidebar-container .list-group-item>span {
  .FontRegular(1rem);
  line-height : 1.5rem;
  padding-left: 1rem;
  opacity     : 1;
  .transitions(all 0.3s ease-in-out);
}

.sidebar-container .list-group-item:hover {
  .borderRadius(0);
  background-color: @colorInteractionDark;
}

.sidebar-container .list-group-item.active {
  background-color: @colorPrimary;
}

.list-group .list-group-item>span.collapsing {
  width   : 100%;
  left    : -100%;
  opacity : 0;
  position: absolute;
}

.list-group .list-group-item>span.show {
  opacity: 1;
}

.sidebarFix.collapse.show+.sidebar-container+.w-100 {
  max-width: calc(~"100% - 18rem");
}

.sidebarFix.collapsing+.sidebar-container+.w-100 {
  max-width: calc(~"100% - 18rem");
}

.sidebarFix.collapse+.sidebar-container+.w-100 {
  max-width: calc(~"100% - 56px");
}

/*=============================================
=            Plan Info            =
=============================================*/
.planInfoWraper {
  position: relative;
  padding : 0.5rem;
}

.planInfo,
.userData {
  background-color: @colorWhite;
  .box-shadow(inset 0 0 0 1px @colorBorders);
  padding    : 0 15px;
  line-height: 2.625rem;
}

.userData {
  padding: 0.5rem 1rem;
}

.planInfo .col-auto:not(.default):first-child,
.planDistribution,
.period {
  background-color: @colorPageTitle;
  color           : @colorWhite;
  width           : 17.75rem;
  .ellipsis;
}

.planInfo .col-auto~.col-auto:not(:nth-child(2)),
.userData .col-auto~.col-auto {
  .box-shadow(inset 1px 0 0 0 @colorBorders);
}

.planInfo .col-auto.noLine,
.userData .col-auto.noLine {
  .box-shadow(0 0 0 0 transparent) !important;
}

.tblGrid tr td.readonly:not(:first-child) {
  border-left: 1px solid @colorGreyWarm;
}

td.required {
  //background-color: lighten(@colorError, 40%);
  background-color: @colorErrorGrid;
  // .box-shadow(inset 1px 0 0 0 @colorWhite);
}

//td.disabled,
// td.readonly {
//   background-color: @colorGreyLight;
//   color: @colorInfoText;
// }

td.disabled,
td.readonly {
  //background-color: @WHITE;
  color           : @FOREGROUNDTD;
}

td.disabled icon-button i,
td.readonly icon-button i {
  color: @colorBlack  !important;
}

.planInfo p,
.userData p {
  .FontMedium(1rem);
  margin       : 0;
  padding-right: 3rem;
  line-height  : normal;
}

.userData p+p {
  .FontRegular(0.875rem);
}

.userData p+div {
  .FontRegular(0.875rem);
  line-height: normal;
}

/*----------  filters  ----------*/
.filters {
  background-color: @colorFilters;
  padding         : 0 15px;
  // margin-bottom: 1rem;
  line-height     : 2.625rem;
  position        : sticky;
  top             : calc(~"-0.5rem + 8px");
  .borderBox;
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15));
  z-index: 10;
}

.filters .custom-control-label::after,
.filters .custom-control-label::before {
  top: 50%;
  .transform(translateY(-50%));
}

.filters.buttonHolder {
  height          : 3.625rem;
  background-color: rgba(@WHITE, 70%);
  border          : 0;
  padding         : 0 0.5rem;
  margin          : 0;
  .box-shadow(none);
  .box-shadow(0 4px 16px -10px @DARK_GREY; );
}

.filters.buttonHolder>div {
  height: 100%;

  align-items: center;
}

.filters.buttonHolder>div>div {
  max-height: 38px;
}

.chioceGroup {
  height     : 2.375rem;
  line-height: 2.375rem;
}

.chioceGroup label {
  margin-bottom: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color    : @colorPrimary;
  background-color: @colorPrimary;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-control {
  line-height: 1.6rem;
}

.custom-control-label {
  font-family: @medium;
  line-height: 1.6rem;
}

.planInfoWraper .filters {
  border: 0;
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15), inset 0 0 0 1px @colorGreyMid;
  );
}

/*----------  Grid Tables  ----------*/
.gridContainer {
  border: 1px solid @colorGreyMid;
}

.table-responsive:not(.noScroll) {
  padding-bottom: 0;
}

.servicetypes_table .table-responsive {
  height: 352px;
}

.servicetypes_table thead {
  position: sticky !important;
  top     : 0;
  z-index : 1;
}

.noScroll {
  overflow: hidden;
}

.table> :not(:first-child) {
  border: 0;
}

.tblGrid {
  margin          : 0;
  border-collapse : collapse;
  border          : 1px solid transparent;
  border-top-color: transparent;
}

.tblGrid tr:not(:first-child),
.tblGrid.dataTable.table-bordered tr:not(:first-child) {
  border-top: 1px solid @colorGreyMid;
}

/* .tblGrid.fixed {
  table-layout: fixed;
} */

.tblGrid.fixed th,
.tblGrid.fixed td {
  .ellipsis;
  vertical-align: middle;
}

.tblGrid.fixed th,
.tblGrid.fixed td {
  white-space   : nowrap;
  text-overflow : ellipsis;
  padding       : 4px 8px;
  overflow      : hidden;
  vertical-align: middle;
}

.tblGrid.fixed th {
  background-color: #173340 !important;
  color           : #ffff !important;
}

.tblGrid thead th {
  .FontRegular(0.875rem);
  vertical-align  : middle !important;
  border-bottom   : none;
  background-color: @COOL_SAPPHIRE5;
  padding         : 0.5rem;
  border-color    : @colorGreyMid;
  border-top-color: @colorGreyWarm;
  color           : @WHITE;
}

.tblGrid tr th:first-child {
  border-left: 1px solid @colorGreyWarm;
}

.tblGrid tr th:last-child {
  border-right: 1px solid @colorGreyWarm;
}

.tblGrid tr,
.tblGrid tr td {
  border        : none;
  vertical-align: middle;
}

.tblGrid tr td i {
  font-size    : 1.375rem;
  padding-right: 0.5rem;
}

.tblGrid tr td.alignField {
  padding-right: 14.8px;
}

.mappedData {
  font-size: 1.125rem;
}

.table-hover tbody tr,
.table-hover tbody tr td {
  .transitions(all 0.3s);
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover td {
  background-color: @colorGreyLight  !important;
}
.table-hover tbody tr.sel,
.table-hover tbody tr.active td {
  background-color: @WARM_RED6 !important;
}


tr.row-deleted td {
  font-family: @bold  !important;
  color      : @colorGreyMid  !important;
}

tr.actionable {
  cursor: pointer;
}

td.required {
  background-color: @colorErrorGrid;

  .box-shadow(inset 1px 0 0 0 @colorWhite);
}

td.disabled {
  background-color: @colorGreyCool;
}

.gridContainer.fixedActions .os-viewport,
.gridContainer.fixedActions .os-content {
  position: static;
}

.gridContainer.fixedActions .os-viewport {
  width: calc(~"100% - 12.5rem");
}

.gridContainer.fixedActions .table-responsive th:last-child,
.gridContainer.fixedActions .table-responsive td:last-child {
  text-align      : center;
  position        : absolute;
  background-color: @colorGreyWarm  !important;
  right           : 0;
  top             : auto;
  width           : 12.5rem;
  line-height     : 1.75;
  margin-top      : -1px;
  .box-shadow(inset 0 1px 0 0 @colorGreyMid, -0.1px 0 0 0 @colorGreyMid;
  );
}

.gridContainer.fixedActions .table-responsive th:last-child {
  .box-shadow(-0.1px 0 0 0 @colorGreyMid);
}

.table-info,
.table-info>td,
.table-info>th,
.table-hover .table-info:hover,
table.dataTable tbody tr.selected,
.td-selected {
  background-color: lighten(@colorLink, 60%) !important;
}

.managedServicesTableCard .table,
.rateCardTable .table,
.pcTable .table {
  table-layout: inherit;
}

.managedServicesTableCard .table thead tr>th,
.rateCardTable .table thead tr>th,
.pcTable .table thead tr>th {
  border: none;
}

.managedServicesTableCard .table thead tr+tr>th,
.rateCardTable .table thead tr+tr>th,
.pcTable .table thead tr+tr>th {
  border   : inherit;
  min-width: 150px !important;
}

.managedServicesTableCard .table thead tr+tr>th:nth-child(1),
.rateCardTable .table thead tr+tr>th:nth-child(1),
.pcTable .table thead tr+tr>th:nth-child(1) {
  min-width: 50px !important;
}

.managedServicesTableCard .table thead tr .columnBorderWhite,
.rateCardTable .table thead tr .columnBorderWhite,
.pcTable .table thead tr .columnBorderWhite {
  border: 1px solid @colorWhite;
}

.columnBorderBlack tbody tr td {
  border: 1px solid @colorBlack  !important;
}

.columnBorderBlack tfoot {
  background-color: @colorGreyDark;
}

.tabfooterGreen tfoot {
  background-color: @COOL_GREEN6;
}

.columncolorGrey {
  background-color: @colorGreyDark  !important;
}

/*----------  Data Tables  ----------*/
.dataTable {
  border         : 0;
  border-collapse: collapse !important;
}

.dataTables_length .custom-select {
  width: auto;
}

.dataTables_length label {
  margin-bottom: 0;
  line-height  : 2.625rem;
  padding      : 0 0.5rem;
}

table.dataTable thead th,
table.dataTable thead td,
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: @colorGreyMid;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

table.dataTable thead th.no-sort {
  padding: 0.5rem;
}

table.dataTable.dt-checkboxes-select tbody tr,
table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
  cursor: pointer;
}

table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
  text-align: center;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  margin-left: 0.5em;
}

@media screen and (max-width: 640px) {

  div.dataTables_wrapper span.select-info,
  div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display    : block;
  }
}

@media screen and (max-width: 1675px) {
  .dealbtn-col button {
    min-width: 13vw;
  }
}

/*----------  Pagination  ----------*/
.pagination {
  background-color: @colorGreyLight;
  margin          : 0;
  border          : 1px solid @colorGreyMid;
  border-top      : 0;
  .borderRadius(0);
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0;
}

.dataTables_paginate .pagination {
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  margin : 0;
  border : 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button .page-link {
  padding    : 0 1rem;
  border     : 0;
  line-height: 2.625rem;
  text-align : center;
}

.page-link {
  font-size       : 1rem;
  font-family     : @medium;
  position        : relative;
  background-color: transparent;
  color           : @colorTextContent;
  border          : 0;
  .borderRadius(0) !important;
}

.page-item.active .page-link {
  background-color: @colorWhite;
  color           : @colorBlack;
  border          : 0;
}

.page-link:hover {
  background-color: @colorGreyMid;
  color           : @colorBlack;
  border          : 0;
}

.page-item.active .page-link::before {
  content   : "";
  position  : absolute;
  top       : 0;
  left      : 0;
  width     : 100%;
  border-top: 2px solid @CAPGEMINI_BLUE;
}

.page-item.disabled .page-link {
  background: transparent;
  cursor    : not-allowed;
  color     : @colorGreyMid;
}

/*=============================================
=            Forzen Grid            =
=============================================*/

.forzenGrid {
  position: inherit;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(-n + 1),
.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 1),
.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  position: -webkit-sticky;
  position: sticky;
}

.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 1),
.forzenGrid .freezRight1.withSelect td:last-child {
  background-color: @WHITE;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(n + 1):not(.freezRight1 th:last-child),
.forzenGrid .freezLeft1.withSelect td:nth-child(n + 1):not(.freezRight1 td:last-child) {
  width    : 300px;
  min-width: 300px;
}

.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  width    : 60px;
  max-width: 60px;
}

.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  right: 0;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(-n + 1),
.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 1) {
  left: 0;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(1),
.forzenGrid .freezLeft1.withSelect td:nth-child(1) {
  // left: 40px;
  left: 0px;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(1):after,
.forzenGrid .freezLeft1.withSelect td:nth-child(1):after {
  content     : "";
  position    : absolute;
  right       : 0;
  top         : 0;
  height      : 100%;
  border-right: 1px @DEEP_PURPLE;
}

.forzenGrid .freezRight1 th:last-child:after,
.forzenGrid .freezRight1 td:last-child:after {
  content    : "";
  position   : absolute;
  left       : 0;
  top        : 0;
  height     : 100%;
  border-left: 3px solid @VIBRANT_BLUE;
}

/*=====  End of Forzen Grid  ======*/

/*=====  End of Table Controls  ======*/

/*----------  Grid Actions  ----------*/
.gridAction {
  background-color: @colorFilters;
  line-height     : 2.635rem;
  text-align      : right;
  overflow        : hidden;
  padding         : 0 0.5rem;
  max-height      : 2.625rem;
  border          : 1px solid @colorBorders;
  border-bottom   : 0;
}

.filters .gridAction {
  background-color: transparent;
  padding         : 0;
}

ul.actionList {
  padding   : 0;
  margin    : 0;
  list-style: none;
}

.form-group ul.actionList {
  min-height  : 31px;
  line-height : 30px;
  padding-left: 2px;
}

ul.actionList li {
  display   : inline-block;
  min-width : 2.625rem;
  text-align: center;
}

ul.actionList li+li {
  margin-left: 0.75rem;
}

ul.actionList.left li {
  margin: 0 0.75rem 0 0;
}

ul.actionList li>a {
  color   : @DEEP_PURPLE;
  position: relative;
  display : block;
  .FontMedium(0.875rem);
  padding: 0 0 0 2.125rem;
  .transitions(all 0.3s);
}

ul.actionList li>a:hover {
  color: @NavendColor;
}

ul.actionList li>a i {
  background-color: @CAPGEMINI_BLUE;
  color           : @colorWhite;
  position        : absolute;
  left            : 0;
  top             : 50%;
  .transform(translateY(-50%));
  width      : 1.5rem;
  height     : 1.5rem;
  line-height: 1.4;
  font-size  : 1.125rem;
  .borderRadius(50%);
  .box-shadow(0 0 0 2px #FFF, 1px 1px 4px -1px rgba(0, 0, 0, 0.5);
  );
}

ul.actionList li>a i.fa {
  font-size  : 0.875rem;
  line-height: 1.5rem;
}

ul.actionList li>a i[class*="done"] {
  background-color: @colorPrimary;
}

ul.actionList li>a i[class*="save"] {
  background-color: @colorSecondary;
}

ul.actionList li>a i[class*="close"],
ul.actionList li>a i[class*="delete"],
ul.actionList li>a i[class*="trash"],
ul.actionList li>a i[class*="log-out"],
ul.actionList li>a i[class*="exit"] {
  background-color: @colorError;
}

ul.actionList li>a i[class*="pdf"] {
  background-color: #f40f02;
}

ul.actionList li>a i[class*="word"] {
  background-color: #005da6;
}

ul.actionList li>a i[class*="excel"] {
  background-color: #1d6f42;
}

td ul.actionList {
  min-height: auto;
}

td ul.actionList li {
  margin   : 0;
  min-width: auto;
}

td ul.actionList li>a:not(.dropdown-item) {
  padding    : 0;
  width      : 1.875rem;
  line-height: 1;
}

td ul.actionList li>a i {
  padding    : 0;
  width      : 1.25rem;
  height     : 1.25rem;
  line-height: 1.25rem;
  font-size  : 1.025rem;
  .transform(translateY(-65%));
}

ul.actionList.panel li a {
  color: rgba(255, 255, 255, 0.6);
}

ul.actionList.panel li a:hover {
  color: @colorWhite;
}

.card-header ul.actionList li>a {
  color: @WHITE;
  .FontRegular(0.875rem);
}

.simpleCard .card-header ul.actionList li>a {
  color: @DEEP_PURPLE;
}

ul.actionList.noLabel li a i {
  // // width: 1.5rem;
  // // height: 1.5rem;
  // // line-height: 1.5rem;
  // // font-size: 1.125rem;
  position   : relative;
  padding    : 0;
  line-height: 1.25rem;
  .transform(translateY(2px));
}

i.ion[class*="mail"]::after {
  content               : "";
  display               : inline-block;
  font-family           : "Ionicons";
  font-style            : normal;
  font-weight           : normal;
  font-variant          : normal;
  text-transform        : none;
  text-rendering        : auto;
  line-height           : 1;
  -webkit-font-smoothing: antialiased;
}

ul.actionList.noLabel li a[aria-label="Forward Email"] i::after {
  content  : "";
  position : absolute;
  color    : @DEEP_PURPLE;
  font-size: 1.5rem;
  top      : -10px;
  left     : 0;
}

ul.actionList.noLabel li a[aria-label="Resend Email"] i {
  background-color: @WARM_VIOLET4;
}

ul.actionList.noLabel li a[aria-label="Resend Email"] i::after {
  content  : "";
  position : absolute;
  color    : @DEEP_PURPLE;
  font-size: 1.5rem;
  bottom   : -10px;
  right    : 0;
  .transform(rotate(-180deg));
}

/*----------  Charts  ----------*/
.P2WCharts {
  width : 100%;
  height: 500px;
}

.borderBox {
  border: 1px solid @colorGreyMid;
}

.noLegends .amChartsLegend {
  visibility: hidden;
}

.chartTitle {
  padding    : 0;
  margin     : 0;
  line-height: 2.375rem;
  .FontMedium(1rem);
  color: @CAPGEMINI_BLUE;
}

.amcharts-axis-label.categoryAxisLabel tspan {
  .FontMedium(0.875rem);
}

.amcharts-axis-label tspan,
.amcharts-legend-label tspan {
  font-size: 0.75rem;
}

.legendWrap {
  width: 500px;
}

a[href*="amcharts"] {
  display: none !important;
}

/*----------  Row Small  ----------*/
.row.small-gutter {
  margin-left : -0.5rem;
  margin-right: -0.5rem;
}

.row.small-gutter [class^="col-"] {
  padding-left : 0.5rem;
  padding-right: 0.5rem;
}

/*----------  Cards  ----------*/
.card-columns {
  column-count        : 2;
  -webkit-column-count: 2;
  -moz-column-count   : 2;
  column-count        : 2;
  -webkit-column-gap  : 1rem;
  -moz-column-gap     : 1rem;
  column-gap          : 1rem;
  orphans             : 1;
  widows              : 1;
}

.card-group {
  margin-bottom: 1rem;
}

.card {
  background   : rgba(@WHITE, 100%);
  margin-bottom: 1rem;
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15));
}

.card .card-header,
.card .card-body,
.card .card-footer {
  .borderRadius(8px 8px 0 0);
}

.card .card-header+.card-body {
  .borderRadius(0);
}

.card .card-footer {
  .borderRadius(0 0 8px 8px);
}

.card .card-body {
  padding: 1rem;
}

.card-group .card-body {
  background-color: @colorGreyLight;
}

.card-group.bgWhite .card-body {
  background-color: @colorWhite;
}

.card .card-header {
  background-color: @COOL_SAPPHIRE5;
  color           : @colorWhite;
  padding         : 0 1rem;
  border          : 0;
  min-height      : 2.75rem;
}

.simpleCard .card-header {
  background-color: @WHITE;
  color           : @colorTextContent;
  border-bottom   : 1px solid @colorGreyLight;
}

.card-header h3 {
  .FontMedium(1.125rem);
  margin     : 0;
  padding    : 0;
  line-height: 2.625rem;
}

.card-body .card-title {
  color      : @colorPageTitle;
  font-family: @medium;
  font-size  : 1.125rem;
}

.panelAction {
  position : absolute;
  color    : @colorWhite;
  font-size: 1.3125rem;
  .transitions(all 0.3s);
}

.panelAction:hover {
  color           : @colorWhite;
  background-color: rgba(0, 0, 0, 0.35);
}

.card-header .panelAction {
  width     : 2.625rem;
  text-align: center;
  right     : 0;
}

.card-header .panelAction+.panelAction {
  right: 2.625rem;
}

.card-title {
  .FontBold(1rem);
}

.card-columns .card .card-body {
  padding: 1rem 1rem 0;
}

.card-header .badge,
.gridAction .badge,
.badge.icon {
  padding: 0.5rem 1rem;
  .FontRegular(0.75rem);
}

/*----------  Link Presence  ----------*/

.linkPresence {
  position    : relative;
  float       : left;
  text-align  : center;
  margin-right: 1rem;
  line-height : 2.625rem;
}

.linkPresence .img-avatar {
  .box-shadow(0 0 0 2px @colorBranding);
}

.linkPresence::after {
  content   : "";
  position  : absolute;
  width     : 0.875rem;
  height    : 0.875rem;
  background: @colorSuccess;
  bottom    : 0.25rem;
  right     : -0.5rem;
  .borderRadius(50%);
  .box-shadow(inset 0 0 0 1px @colorWhite);
}

.userInfo {
  padding   : 0;
  margin    : 0;
  list-style: none;
}

.userInfo li {
  line-height: 2rem;
  .ellipsis;
}

.userInfo li:not(:last-child) {
  border-bottom: 1px solid @colorGreyMid;
}

.userInfo span {
  display  : inline-block;
  min-width: 6.25rem;
  .FontMedium(inherit);
}

.peopleName {
  margin: 0;
  .FontRegular(1rem);
}

.userList {
  background-color: @colorWhite;
  .borderBox;
  padding   : 1rem;
  max-height: 20rem;
}

.userList>.row:not(:last-child),
.userList .os-content>.row:not(:last-child) {
  margin-bottom: 1rem;
}

.userItems {
  .borderBox;
  padding: 0.5rem;
  cursor : pointer;
  .transitions(all 0.3s ease-in-out);
}

.userItems:hover {
  background-color: @colorGreyCool;
}

.userItems .panelAction {
  background: transparent;
  color     : @colorTextContent;
  width     : 2.625rem;
  text-align: center;
  display   : block;
}

.userItems .panelAction:hover {
  background: transparent;
  color     : @colorBlack;
}

.userItems.active {
  background-color: lighten(@colorLink, 60%);
}

.userItems .linkPresence .img-avatar {
  .box-shadow(0 0 0 2px @colorBranding);
}

.userItems .media-body {
  max-width: calc(~"100% - 5.625rem");
}

.userName {
  font-family: @medium;
  margin     : 0;
  padding    : 0 0.5rem 0 0;
  .ellipsis;
}

.infoText {
  font-size: 0.75rem;
  color    : @colorInfoText;
}

.userData>.row {
  min-height: 2.625rem;
}

/*----------  Simple Icon Link  ----------*/
.iconLink,
.cellLink {
  font-family: @medium;
  color      : @colorLink;
  .transitions(all 0.3s);
}

.iconLink:hover,
.cellLink:hover {
  color : darken(@colorLink, 20%);
  cursor: pointer;
}

.iconLink .activeView {
  color: @colorBlack;
}

/*----------  Normal Lists  ----------*/
ol {
  padding            : 0 0 0.75rem 0.75rem;
  margin             : 0;
  list-style-position: inside;
}

ol li {
  line-height: normal;
  font-size  : 0.75rem;
}

ol li~li {
  margin-top: 0.75rem;
}

/*----------  Tree List  ----------*/
.treeList {
  background-color: @colorGreyWarm;
  height          : 100%;
  min-width       : 300px;
  width           : calc(300px);
  border-top      : 1px solid @colorBorders;
  .box-shadow(inset 1px 0 0 0 @colorGreyLight);
}

.treeList .list-group li,
.treeList .list-group li .list-group-item {
  border: 0;
}

.treeList .list-group .treeCollapse:not(:last-child) {
  border-bottom: 2px solid @colorGreyMid;
}

.treeList .list-group li:not(:last-child) {
  border-bottom: 1px solid transparent;
}

.treeList .list-group-item {
  background-color: @colorGreyLight;
  color           : @colorTextContent;
  .FontRegular(1rem);
  padding : 0.5rem 1rem;
  position: relative;
  // .box-shadow(inset -1px 0 0 0 @colorGreyMid);
  .transitions(all 0.3s ease-in-out);
}

.treeList .list-group-item:hover {
  background-color: @colorGreyMid;
  color           : @colorBlack;
}

.treeList .list-group-item.active,
.treeCollapse .list-group li .list-group-item.active {
  background-color: @NavstartColor;
  color           : @colorWhite;
}

.treeList .list-group-item.active:after {
  content      : "";
  position     : absolute;
  width        : 0;
  height       : 0;
  right        : 0;
  top          : 50%;
  border-top   : 8px solid transparent;
  border-right : 8px solid @WHITE;
  border-bottom: 8px solid transparent;
  .transform(@transformY);
}

.treeList .list-group-item i {
  font-size    : 1.375rem;
  float        : left;
  // margin: -0.25rem 0.5rem 0 0;
  width        : 1.375rem;
  height       : 1.375rem;
  background   : @colorGreyLight;
  text-align   : center;
  line-height  : 1.375rem;
  border-radius: 100%;
}

.treeList .list-group-item.collapsed i::before {
  content: "";
}

.treeCollapse .list-group li .list-group-item {
  font-size       : 0.875rem;
  padding-left    : 2.75rem;
  background-color: @colorGreyLight;
}

.treeCollapse .list-group .list-group-item:hover {
  background-color: @colorGreyMid;
}

/*----------  Popover  ----------*/

.popover {
  max-width: 25rem;
  .borderRadius(0);
  border-color: @colorGreyMid;
  padding     : 0.5rem;
  .box-shadow(0 0 1.5rem 0 rgba(0, 0, 0, 0.5));
}

.popover.size-xl {
  min-width: 40rem;
  max-width: 120rem;
}

.popover-header {
  .ellipsis;
  background-color: @colorTextContent;
  color           : @colorWhite;
  font-family     : @medium;
  .borderRadius(0);
}

.popover-body {
  padding: 0.5rem 0;
}

/*----------  Alert  ----------*/
.alert {
  padding    : 0 1rem;
  line-height: 2.375rem;
  .borderRadius(2px);
}

.alert.alert-dismissible {
  line-height  : 2rem;
  margin       : 0;
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  padding  : 0.75rem 1rem;
  font-size: 0.625rem;
  top      : -1px;
}

.alert-dismissible+.alert-dismissible {
  margin-left: 0.5rem;
}

/*----------  Modal  ----------*/
.modal-content {
  .box-shadow(0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.35));
}

.modal-content,
.modal-header,
.modal-body {
  border: 0;
  .borderRadius(0);
}

.modal-body {
  background-color: @WHITE;
}

.modal-footer {
  .borderRadius(0);
}

.modal-header {
  background-color: @WHITE;
  color           : @colorTextContent;
}

.modal-title {
  .FontBold(1rem);
}

.modal-header .close {
  opacity    : 1;
  color      : rgba(255, 255, 255, 0.5);
  text-shadow: none;
  .transitions(all 0.3s);
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
  color  : @colorWhite;
}

.modal-body.size-md {
  max-height: 25rem;
}

.modal-xl {
  min-width: calc(~"100% - 3rem");
}

/*----------  Form Controls  ----------*/

.form-group label {
  .FontRegular(0.875rem);
  margin-bottom: 5px;
  display      : block;
}

.custom-control-label {
  .FontMedium(inherit);
}

.form-inline .form-group label,
.form-inline .form-control,
.form-inline .form-group label+div {
  display       : inline-block;
  width         : 100%;
  vertical-align: middle;
}

.form-inline .form-check {
  margin-bottom: 0;
}

.form-inline .form-group label,
.form-inline .form-group .form-check-label {
  line-height  : 31px;
  margin-bottom: 0;
}

.form-group,
.form-inline .form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control,
.form-inline .form-group label+div {
  width: 100%;
}

.form-inline label:not(.form-check-label)::before {
  content: "";
  float  : right;
  padding: 0 0.5rem;
}

.form-inline .form-check-input {
  margin-top: 0;
  .transform(translateY(calc(~"50% + 2px")));
}

.form-inline .form-group .form-check-label {
  width      : auto;
  margin-left: 0;
}

.form-control,
.input-group-text {
  .borderRadius(2px);
  border-color: @colorGreyMid;
  font-family : @medium;
}

.input-group-text {
  background-color: @colorGreyLight;
}

.rounded-pill.input-group>.btn,
.rounded-pill.input-group>.form-control,
.rounded-pill.input-group>.form-select,
.rounded-pill.input-group>.input-group-text,
.rounded-pill.input-group-sm>.btn,
.rounded-pill.input-group-sm>.form-control,
.rounded-pill.input-group-sm>.form-select,
.rounded-pill.input-group-sm>.input-group-text,
.rounded-pill.input-group-lg>.btn,
.rounded-pill.input-group-lg>.form-control,
.rounded-pill.input-group-lg>.form-select,
.rounded-pill.input-group-lg>.input-group-text {
  .borderRadius(50rem);
}

.rounded-pill.input-group>.form-control,
.rounded-pill.input-group-sm>.form-control,
.rounded-pill.input-group-lg>.form-control {
  border-right-color: @WHITE;
}

.rounded-pill.input-group .btn,
.rounded-pill.input-group-sm .btn,
.rounded-pill.input-group-lg .btn {
  border-left: 0;
}

.rounded-pill.input-group .btn i,
.rounded-pill.input-group-sm .btn i,
.rounded-pill.input-group-lg .btn i {
  padding: 0;
}

.rounded-pill .input-group-text {
  background-color: @WHITE;
}

.readonly .form-control,
.form-control-plaintext {
  .FontMedium(1rem);
}

.formData,
.formFields {
  background-color: @colorWhite;
  padding         : 1rem 1rem 0;
}

.formFields,
.gridForm {
  position       : relative;
  height         : calc(~"100% - 2.625rem");
  overflow       : auto;
  scroll-behavior: smooth;
}

.gridForm {
  background-color: @colorWhite;
  padding         : 0.5rem;
  .box-shadow(inset 0 0 0 1px @colorGreyMid);
}

.formData label {
  margin: 0;
}

textarea {
  min-height: 31px;
}

select {
  padding-right: 2.25rem !important;
  outline      : none !important;
  .box-shadow(none) !important;
  box-sizing         : border-box;
  -moz-appearance    : none;
  -webkit-appearance : none;
  appearance         : none;
  background-image   : url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%233f3f3f%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat  : no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size    : 0.5rem auto, 100%;
  .borderRadius(2px);
}

.form-select {
  .borderRadius(2px);
}

.select-css::-ms-expand {
  display: none;
}

.input-group-text.currency-text,
select.currency-selector {
  background-color: @colorWhite;
  border-color    : @colorBorders;
  border-radius   : 0;
  outline         : 0;
  height          : 2rem;
}

.input-group-text.currency-text {
  font-size: 1.5rem;
}

select.currency-selector {
  width       : 80px;
  padding-left: 0.313rem;
}

.badge {
  .FontRegular(0.625rem);
  letter-spacing: 0.8px;
}

.badge.icon {
  position      : relative;
  //padding-left: 2rem;
}

.badge.icon i {
  font-size    : 1.375rem;
  position     : absolute;
  width        : 1.375rem;
  height       : 1.375rem;
  text-align   : center;
  line-height  : 1.375rem;
  border-radius: 100%;
  left         : 0.25rem;
  top          : 50%;
  .transform(@transformY);
}

label.required {
  position: relative;
}

label.required:after {
  content     : "*";
  padding-left: 0.25rem;
  color       : @WARM_RED4;
  .FontMedium(1rem);
}

span.required {
  color: @WARM_RED4;
  .FontMedium(1rem);
}

/*----------  Custom File  ----------*/
.custom-file-label {
  text-align: left;
  margin    : 0;
  .borderRadius(2px);
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  font       : normal normal normal 18px/1 FontAwesome;
  content    : "\f0c6";
  background : none;
  border     : none;
  line-height: 2.235rem;
  color      : @colorGreyMid;
  padding    : 0 0.75rem;
  height     : 100%;
}

.gridAction .custom-file {
  margin-top: 3px;
}

/*----------  Select 2  ----------*/

.input-group .select2-container {
  min-width: 100%;
}

.select2-container--bootstrap {
  width: 100% !important;
}

.select2-dropdown {
  .borderRadius(2px);
}

.select2-container--bootstrap .select2-selection {
  font-size   : 1rem;
  font-family : @medium;
  border-color: @colorGreyMid;
  .borderRadius(2px);
  .box-shadow(none);
}

/*----------  Custom Select  ----------*/

.ng-select.custom {
  .FontMedium(inherit);
  border       : 0px;
  min-height   : 0px;
  border-radius: 0;
}

.ng-select .ng-select-container .ng-value-container,
.ng-select .ng-select-container .ng-value-container .ng-value,
.ng-select.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  .FontMedium(0.875rem);
  color: @colorTextContent;
}

.ng-placeholder {
  color: @colorDisabledText;
}

.ng-select.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  color: @colorDisabledText;
}

.ng-select .ng-select-container {
  min-height  : 31px;
  padding     : 0px 5px;
  border-color: @colorGreyMid;
  .borderRadius(2px);
}

.ng-select.custom .ng-select-container {
  border-radius: 0;
  width        : 100%;
  .borderRadius(2px);
}

.ng-select.ng-select-multiple .ng-select-container {
  // min-height: 2.375rem;
  .borderRadius(2px);
 // width  :fit-content;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 0px 5px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  .FontMedium(0.875rem);
  color: @colorTextContent;
}

.ng-dropdown-panel {
  width       : auto !important;
  // max-width: 350px;
  min-width   : 100%;
  border      : none;
  margin-top  : 0;
  margin-left : 0;
  padding     : 0.5rem;
  .box-shadow(inset 0 0 0 1px @colorGreyMid,
    0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15);
  );
  z-index: 1060 !important;
  .borderRadius(0 0 2px 2px);
}

.ng-option-label {
  font-family: @medium;
}

// .form-control:focus,
// .ng-select.ng-select-opened>.ng-select-container {
//   .box-shadow(none);
//   border-color: @colorGreyMid;
//   .box-shadow(0 0 0.5rem 0 lighten(@colorSecondary, 50%));
// }

.ng-select .ng-arrow-wrapper .ng-arrow {
  border      : solid @colorGreyMid;
  border-width: 0 2px 2px 0;
  padding     : 2px;
  top         : 0;
  .transform(rotate(45deg));
}

.ng-select .ng-arrow-wrapper {
  line-height: 20px !important;
}

.ng-select .ng-clear-wrapper {
  width        : 15px !important;
  height       : 1rem;
  border-radius: 1rem;
  right        : 0;
}

.ng-select .ng-clear-wrapper .ng-clear {
  display    : block !important;
  line-height: 1rem !important;
  font-size  : 1rem !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 1rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  max-width: 100%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  max-width: calc(100% - 2rem);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size    : 1rem;
  max-width    : calc(100%);
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
  padding-left : 1.25rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  position: absolute;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color: @VIBRANT_BLUE;
}

.form-group-sm .select2-container--bootstrap .select2-selection--single,
.input-group-sm .select2-container--bootstrap .select2-selection--single,
.select2-container--bootstrap .select2-selection--single.input-sm {
  font-family: @medium;
  font-size  : 0.875rem;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  margin: 2px 0 0 6px;
}

.select2-container--bootstrap .select2-selection--multiple {
  min-height: 26px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  height: 30px;
}

/*----------  Custom Form  ----------*/

.customForm .filters,
.customForm .filters .selectedFilters {
  line-height: inherit;
  min-height : 4.75rem;
}

.customForm label {
  .FontRegular(inherit);
  font-size: 0.875rem;
  margin   : 5px 0;
}

.customForm .row {
  padding-bottom: 0.5rem;
}

.customForm .form-group {
  margin-bottom: 0;
}

td>.form-group {
  margin-bottom: 0;
}

/*----------  Buttons  ----------*/

.formActions {
  text-align: right;
  padding   : 6px 0;
}

.gridForm+.formActions {
  padding: 6px 0.5rem;
}

.btn {
  .borderRadius(2px);
  border-color: @colorGreyMid;
}

.btn-outline-primary {
  border: 1px solid @CAPGEMINI_BLUE  !important;
  color : @colorButtonText  !important;
}

.slider-btn {
  border    : 1px solid @CAPGEMINI_BLUE  !important;
  color     : @colorBlack  !important;
  transition: all 0.2s ease-out;
}

.slider-btn-group {
  background: @WHITE;
}

.slider-btn:focus:not([disabled]),
.slider-btn:hover:not([disabled]) {
  background-color: @CAPGEMINI_BLUE;
  color           : @WHITE  !important;
  transform       : scale(1.1);
}

.slider-btn:active:not([disabled]) {
  background-color: @CAPGEMINI_BLUE;
  color           : @WHITE  !important;
}

.slider-btn.active {
  background-color: @CAPGEMINI_BLUE;
  color           : @WHITE  !important;
}

.btn-outline-secondary {
  border-color: @colorGreyMid;
}

.input-group>.input-group-append>.btn {
  width: 2.375rem;
}

.btn-outline-secondary:hover,
.gj-datepicker-bootstrap [role="right-icon"] button:hover {
  background-color: transparent;
  border-color    : @colorGreyMid;
  color           : @colorSecondary;
}

.gridAction .input-group>.input-group-append>.btn {
  background-color: @colorWhite;
}

/*----------  Custom Buttons  ----------*/
.btnCustom,
.btn-group-toggle .btn {
  border     : 0;
  max-height : 2.375rem;
  line-height: 2.375rem;
  padding    : 0 1rem;
  .FontRegular(1rem);
  cursor: pointer;
  .borderRadius(2px);
  .transitions(all 0.3s ease-in-out);
}

.btnCustom.small,
.btn-group-toggle .btn {
  line-height: 1.875rem;
  padding    : 0 0.5rem;
  font-size  : 0.875rem;
}

.btn-group-toggle .btn~.btn {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 0;
}

.btn-group-toggle .btn~.btn,
.btn-group-toggle .btn:first-child:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-group-toggle .btn:last-child {
  border-right: 0;
}

.btnCustom i,
.btn i {
  padding-right: 0.5rem;
}

.btnCustom span+i {
  padding-left : 0.5rem;
  padding-right: 0;
}

.btnCustom:not(:first-child) {
  margin-left: 0.5rem !important;
}

.btnPrimary {
  background-color: @colorPrimary;
  color           : @colorWhite;
}

.btnPrimary:focus:not([disabled]),
.btnPrimary:hover:not([disabled]) {
  background-color: @colorWhite;
  color           : @colorPrimary;
  .box-shadow(inset 0 0 0 1px @colorPrimary);
}

.btnSecondary,
.btn-secondary {
  background-color: @colorSecondary;
  color           : @colorWhite;
}

.btnSecondary:focus:not([disabled]),
.btnSecondary:hover:not([disabled]),
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  background-color: @colorWhite;
  color           : @colorSecondary;
  .box-shadow(inset 0 0 0 1px @colorSecondary);
}

.btnNegative {
  background-color: @colorGreyMild;
}

.btnNegative:focus:not([disabled]),
.btnNegative:hover:not([disabled]) {
  background-color: @colorWhite;
  color           : @colorBlack;
  .box-shadow(inset 0 0 0 1px @colorGreyMild);
}

.btnCustom:disabled,
.btnCustom[disabled] {
  cursor : not-allowed;
  opacity: 0.5;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.5px 1rem;
}

.btnCustom .badge {
  background-color: @WARM_RED4;
  position        : absolute;
  top             : 0;
  padding         : 0.25rem;
  width           : 1.5rem;
  height          : 1.5rem;
  line-height     : 0.875rem;
}

.btnCustom:focus:not([disabled]),
.btnCustom:hover:not([disabled]) {
  background-color: @colorBtnHover;
  color           : #15051d;
  transform       : scale(1.1);
  border          : 1px solid @colorBtnHover;
}

/* Button Animation */
.btnTertiary {
  background-color: @WHITE;
  border          : 1px solid @colorSecondary;
  color           : @colorSecondary;
  transition      : all 0.2s ease-out;
  overflow        : hidden;
  position        : relative;
  font-weight     : 400;
}

.btnTertiary:hover:before,
.btnTertiary:focus:before,
.btnTertiary:focus-within:before {
  transform: translate(0);
}

.btnTertiary:before {
  content         : "";
  display         : block;
  background-color: @colorBtnHover;
  position        : absolute;
  left            : -1px;
  top             : -1px;
  transform       : translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition      : transform 0.2s ease-out;
  z-index         : -1;
  height          : calc(100% + 2px);
  width           : calc(100% + 2px);
  border-radius   : 48px;
}

.btnTertiary:hover,
.btnTertiary:focus,
.btnTertiary:focus-within {
  color       : @colorSecondary;
  border-color: @colorBtnHover;
  box-shadow  : 0 6px 30px 0 rgb(0 0 0 / 20%);
  transform   : scale(1.2);
  z-index     : 1;
  background  : @colorBtnHover;
  font-weight : 500;
}

.btnInfo {
  background-color: @colorBtnHover;
  border          : 1px solid @colorBtnHover;
  color           : @colorSecondary;
  transition      : all 0.2s ease-out;
  overflow        : hidden;
  position        : relative;
  font-weight     : 400;
}

.btnInfo:focus:not([disabled]),
.btnInfo:hover:not([disabled]) {
  background-color: @colorWhite;
  color           : @colorBlack;
  .box-shadow(inset 0 0 0 1px @colorGreyMild);
}

.btnInfo:hover:before,
.btnInfo:focus:before,
.btnInfo:focus-within:before {
  transform: translate(0);
}

.btnInfo:before {
  content         : "";
  display         : block;
  background-color: @colorSecondary;
  position        : absolute;
  left            : -1px;
  top             : -1px;
  transform       : translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition      : transform 0.2s ease-out;
  z-index         : -1;
  height          : calc(100% + 2px);
  width           : calc(100% + 2px);
  border-radius   : 48px;
}

.btnInfo:hover,
.btnInfo:focus,
.btnInfo:focus-within {
  color       : @colorWhite  !important;
  border-color: @colorSecondary  !important;
  box-shadow  : 0 6px 30px 0 rgb(0 0 0 / 20%);
  transform   : scale(1.2);
  z-index     : 1;
  background  : @colorSecondary;
  font-weight : 500;
}

/* #region */
.progress {
  .borderRadius(0);
}

/*----------  Badges  ----------*/

.badge {
  font-family   : "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size     : 0.625rem;
  letter-spacing: 0.8px;
}

.badge~.badge {
  margin-left: 0.5rem;
}

.badge-warning {
  color           : @colorWhite;
  background-color: @colorWarning;
}

.badge-success {
  color           : @colorWhite;
  background-color: @colorSuccess;
}

.badge-danger {
  color           : @colorWhite;
  background-color: #dc3545;
}

.badge-info {
  color           : @colorWhite;
  background-color: #17a2b8;
}

.badge-secondary {
  background-color: @colorSecondary;
}

.badge-dark {
  background-color: @colorPageTitle;
}

.badge-expired {
  background-color: @colorDisabledText;
  color           : @colorWhite;
}

.gridLegends {
  padding         : 0.5rem;
  background-color: @colorGreyWarm;
  border          : 1px solid @colorGreyMid;
  border-top      : 0;
}

.gridLegends .badge {
  .borderRadius(10rem);
}

/*----------  Loading  ----------*/
.loading {
  background       : rgba(255, 255, 255, 0.5);
  position         : fixed;
  height           : 100%;
  top              : 50%;
  left             : 0;
  right            : 0;
  transform        : translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform   : translateY(-50%);
  -ms-transform    : translateY(-50%);
  -o-transform     : translateY(-50%);
  z-index          : 999999;
}

.loader {
  width           : 56px;
  height          : 56px;
  border          : 8px solid rgba(68, 102, 119, 0.35);
  border-top-color: #007eaf;
  border-radius   : 50%;
  position        : relative;
  animation       : loader-rotate 1s linear infinite;
  top             : 50%;
  margin          : -28px auto 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*SIDEBAR STYLES STARTS*/
// .list-group-item i {
//   display   : inline-block;
//   width     : 32px;
//   height    : 32px;
//   background: green;
// }

// div#sidebar {
//   background-color: #2b0a3d;
// }

// /*SIDEBAR STYLES STARTS*/
// .list-group-item i {
//   display   : inline-block;
//   width     : 32px;
//   height    : 32px;
//   background: green;
// }

// div#sidebar {
//   background-color: #2b0a3d;
// }

// .select2-container--bootstrap .select2-selection--multiple {
//   min-height: 26px;
// }

/*P2W EDITED STYLES*/

.btnCustom:focus:not([disabled]),
.btnCustom:hover:not([disabled]) {
  background-color: #00e6e3;
  color           : #15051d;
  transform       : scale(1.1);
  border          : 1px solid #00e6e3;
}

/* Dashboard */

.dashboard-status .list-unstyled {
  margin: 0;
}

.dashboard-status .list-unstyled:not(:last-child) {
  margin-bottom: 0.5rem;
}

.dashboard-status .list-unstyled .flex-fill {
  align-self: center;
}

.dashboard-status .list-unstyled li:first-child {
  background-color: @colorGreyLight;
  padding         : 0.5rem;
}

.dashboard-status .list-unstyled .flex-fill a {
  display   : inline-block;
  min-width : 4rem;
  text-align: right;
  padding   : 0.5rem;
}

.dashboard-status .list-unstyled li+li {
  margin-left: 0.5rem;
}

div#chart-status {
  height: 257px;
}

a.collapseTrigger {
  color   : @colorBlack;
  display : block;
  position: relative;
}

a.collapseTrigger>i {
  color       : rgba(0, 0, 0, 0.3);
  margin-right: 0.625rem;
  .transitions(all 0.3s ease);
  .transform(rotate(0deg));
}

a.collapseTrigger.collapsed>i {
  .transform(rotate(-90deg));
}

a.collapseTrigger.collapsed>.on,
a.collapseTrigger>.off {
  margin-right: -50%;
  display     : none;
  .transitions(all 0.3s ease);
}

a.collapseTrigger.collapsed>.off,
a.collapseTrigger>.on {
  margin-right: 0;
  display     : inline-block;
  .transitions(all 0.3s ease);
}

/* Accordian */
.accordion-flush .accordion-body,
.accordion .accordion-body {
  padding: 0 1rem 1rem;
}

.accordion-flush .accordion-button,
.accordion .accordion-button {
  padding: 1rem 0 1rem 2rem;
}

.accordion .accordion-header {
  margin-bottom: 0;
  padding      : 0 1rem;
}

.accordion-button:not(.collapsed) {
  color           : @CAPGEMINI_BLUE;
  border          : 0;
  background-color: @WHITE;
  .box-shadow(none);
}

.accordion-flush .accordion-button .badge,
.accordion .accordion-button .badge {
  margin-left: auto !important;
}

.accordion-flush .accordion-button::after,
.accordion .accordion-button::after {
  position: absolute;
  left    : 0;
  .transform(rotate(270deg));
}

.accordion-flush .accordion-button:not(.collapsed)::after,
.accordion .accordion-button:not(.collapsed)::after {
  .transform(rotate(360deg));
}

.accordion-button.bg-danger {
  background-color: rgba(@WARM_RED4, 10%) !important;
  color           : @colorTextContent  !important;
}


/*CIS MAIN PAGE CSS starts*/
.greyCISBackground{
  background-color: #d9d9d9;
  padding-top: 10px !important;
  padding-right: 0px !important;
}

.whiteCISBackground{
  background-color: #ffffff;
  border-radius: 10px;
}

ul.square-box{
  list-style-type: disc;
}

.hrCIS{
  height: 2px !important;
  background-color: #005a8b;
  margin-top: 5px;
}

.fontColorCIS{
  color: #035c8d;
}

.whitefontColorCIS{
  color: #ffffff !important;
}

.marginCIS{
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.mainCISPara{
  width: 98%;
}

.mainCISParaHeading{
  word-spacing: 7px;
  font-size: 22px;
  font-weight: bold;
}

.mainParaList{
  list-style-type: disc;
  margin-bottom:4px;
}

.CISCardBody{
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 5px;
}

.mainCISimg{
  height: 200px;
  width: 100%;
}


.sectionCISDiv{
  width: 17.6%;
  border-radius: 12px;
  color: #ffffff;
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
}

.sectionCISImg{
  height: 45px;
  width: 47px;
}

.listCISImg{
  height: 22px;
  width: 22px;
}

.bottomSectionCISHeading{
  height: 35px;
  width: 37px;
}

.sourcesCISImg{
  height: 33px;
}

.middleCISSection{
  width: 18%;
  background-color: #ffffff; 
  border-radius: 10px
}

.middleCISSectionBtn{
  width: 85%;
  padding-top: 4px;
  padding-bottom: 56px;
  text-align: left;
  border-radius: 10px;
}

.middleSecBtnPadding{
  padding-left: 0.5rem!important;
  padding-top: 1rem!important;
}

.middleSectionCISPara{
  font-size: 14px;
  line-height: 18px;
  word-spacing: 3px;
}

.middleSecBtnPadding{
  padding-left: 0.5rem!important;
  padding-top: 1rem!important;
}

.middleSecBtnNoMarginLeft{
  margin-left: 0rem !important;
}

.marqueeUl{
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.mainSecHeading{
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}

.downloadCISImg{
  height: 22px;
  width: 22px;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
}

.roundCircle{
  height: 32px;
  width: 32px;
  border-radius: 70px;
  background: #ffffff;
  float: right;
  margin-top: -6px;
}

.listCISLeft{
  width: 50%;
  float: left;
}

.listCISRight{
  width: 50%;
  float: right;
}
/*CIS MAIN PAGE CSS ends*/

/* #endregion */

/*=============================================
=            Login Module            =
=============================================*/

.bodyContainer.login {
  background-color: @colorGreyCool;
}

.bgWrapper {
  width          : 100%;
  height         : 100%;
  background     : @colorGreyCool url("../images/loginBackground.png") no-repeat center center;
  background-size: cover;
}

.customPosition .loginContainer {
  position : absolute;
  left     : 70%;
  top      : 50%;
  transform: translate(-50%, -50%);
}

.loginContainer .card {
  min-width: 500px;
}

.loginContainer h2 {
  font-size    : 1.5rem;
  margin-bottom: 1.5rem;
}

.line-image {
  position: relative;
}

.borderRed{
  border:1px solid red;
}

.line-image::after {
  content        : "";
  background     : url(../images/Capgemini-Line.svg) no-repeat;
  background-size: cover;
  width          : 100%;
  height         : 100%;
  position       : fixed;
  top            : 0;
  left           : 0;
}

/*=====  End of Login Module  ======*/

/*=====  End of Body Section  ======*/

/* Place Holders */

::-webkit-input-placeholder {
  /* Edge */
  .FontRegular(0.875rem);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  .FontRegular(0.875rem);
}

::placeholder {
  .FontRegular(0.875rem);
}

/* Scrollbar*/
::-webkit-scrollbar {
  width : 0.9rem;
  /* for vertical scrollbars */
  height: 0.9rem;
  /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background   : rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

.dynamicContent {
  display: none;
}

#flexSwitchCheckDefault:checked~.dynamicContent {
  display: block;
}

.hoverTip {
  width: max-content !important;
}

.linkCstm:hover {
  text-decoration: underline;
  color          : @colorLinks  !important;
}

.tooltip-inner {
  background-color: @WHITE;
  border          : 1px solid @colorSecondary;
  color           : @colorSecondary;
}

.myDealCard .card-body .form-inline .form-group span img {
  width: 18px;
}

.badge.rounded-pill {
  min-width: 10em;
}

/**Additional css**/
.ng-dropdown-panel.ng-select-bottom {
  z-index: 11111;
}

.ion-ios-trash:before,
.ion-ios-alert:before,
.ion-ios-checkmark-circle:before,
.ion-ios-hourglass:before {
  padding-right: 10px !important;
  font-size    : 16px;
}

.deal-details {
  padding : 5px;
  position: relative;
  line-height: 25px;
}

.admin-img {
  width: 32px;
}

.badge-count {
  min-width: 55px;
  height   : 55px;
  font-size: 17px;
  border   : none;
}

.badge-count.active {
  border: 4px solid @WARM_RED5;
}

.dealbtn-col {
  border-left: 1px solid @DEFAULT_GREY;
}

.count-col {
  font-size: 18px;
}

.disabledtabs {
  pointer-events: none;
}

.guidelines {
  border: 1px solid @DARK_GREY;
}

.guidelines span {
  color: @WARM_RED5;
}

.download-btn {
  color : #0070ad !important;
  border: 1px solid #0070ad !important;
}

.inflation-button button {
  margin-left: 3rem !important;
  border     : 0;
  max-height : 2.375rem;
  line-height: 2.375rem;
  padding    : 0 1rem;
  .FontRegular(1rem);
  cursor: pointer;
  .borderRadius(2px);
  .transitions(all 0.3s ease-in-out);
}

.sub-list-group {
  background-color: @subnavCol;
}

.HeaderHelpIconTooltip .fa-ques:hover {
  color: #12abdc;
}

.HeaderHelpIconTooltip .fa-ques {
  float      : right;
  line-height: 2.625rem;
  color      : #000000;
}

.helpIconTooltip .fa-ques:hover {
  color: #12abdc;
}

.helpIconTooltip .fa-ques {
  float      : right;
  color      : #000000;
}

.headerProgress{
  width: 50%;
  height: 21px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
}

.headerProgressPadding{
  padding:1rem 1rem 0 1rem;
}

.headerProgressBar{
  color:#fff;
}

.staffingModelResourcePyramidFirst{
  padding: 0.375rem 2.75rem;
}

.staffingModelResourcePyramidLast{
  padding: 0.375rem 1.75rem;
}

.overflowAuto{
  overflow: auto;
}

.helpIconFilters{
  background-color: #BFBCB8;
  padding: 0 15px;
  line-height: 2.125rem;
  border: 1px solid #d1d1d1;
}

.gridFilters{
  background-color: #f7f7f7;
  padding: 0 15px;
  line-height: 2.125rem;
  border: 1px solid #d1d1d1;
}

.borderBottom {
  border-bottom: 1px solid #CCCCCC;;
}

.textwrap {
  white-space: pre-wrap
}

.greyinputbox{
  width: 50px;
  background-color: #E8ECEF;
  border: 1px solid #ccc;
}
.phead{background-color:#ececec;margin-bottom:0px; padding:5px}

.form-range {
  width: 50%;
  height: 1.5rem;
  padding: 12px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.nav-link_upd {
  display: block;
  padding: 0.5rem 1rem;
  color: #000000;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.nav-link_upd active{
  display: block;
  padding: 0.5rem 1rem;
  color: #0070ad;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

//@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

//body{font-family:'Ubuntu', sans-serif; font-size:2.15vh; line-height:3vh;}

.p2w-landing-page, .cis-landing-page{
  font-family:'Ubuntu', sans-serif; font-size:2.15vh; line-height:3vh;
  .footer{
    padding:0;
  }
}
// body{font-family:'Ubuntu', sans-serif; font-size:2.15vh; line-height:3vh;} 
body, ul, ol, li, p, h1, h2, h3, h4, h5, h6{margin:0; padding:0;}
li{list-style:none;}
img{object-fit:cover;}
.clear{clear:both;}
.lefter{float:left;}
.righter{float:right;}

/*Base Structure :Begin*/
#wrapper, #header, #container, #platform, #partition, .heading{clear:both;}
#wrapper{
  width:100%;
  height:100%;
  #header{
    overflow:hidden;
  }
  #container{
    background:#ECECEC;
    #platform{
      padding:0 15px;
      overflow:hidden;
      background:#fff;
    }
    #partition{
      overflow:hidden;
      background:#ECECEC;
    }
  }
}
#panel-{
  &left{width:39%; float:left;
    &-content{margin:0 3vh 0 0; overflow:hidden;}
  }
  &right{width:100%; float:left;}
}
/*Base Structure :End*/

/* Heading :Begin*/
.heading{
  h2, h3{text-align:left; font-size:24px; line-height:24px; font-weight:400;}
  h3{color:#000; padding:53px 0 0;}
} 
/* Heading :End*/

/*Header :Begin*/
#header{
  padding:18px 48px 11px;
  background:#fff;
  .capgemini{
    width:180px;
    height:40px;
    float:left;
    background:url(../images/logo.png) 0 0 / 100% 100% no-repeat;
  }
}
/*Header :End*/

/*Banner :Begin*/
#banner{background:url(../images/Picture1.jpg) no-repeat center top 20%; height:510px; background-size:103% 200%; padding:0 24px; position:relative; border-bottom:110px solid #ECECEC;}
.p2w{float:left; clear:both;width:237px; height:92px; background:url(../images/logonew.png) 0 0 / 100% 100% no-repeat; background-size:cover; margin:28px 0 0;}
.jumbotron{
  clear:both; max-width:100%; padding:22px 19px 20px 20px; margin:62px 0 0 0; /*max-width:643px; min-height:238px; padding:42px 0 0 30px; margin:62px 0 0 312px;*/ background:linear-gradient(220deg, #007BBD 1.76%, #003F61 94.25%); opacity:0.9; border-radius:5px;
  h1{
    font-size:40px; font-weight:500; line-height:42px; text-transform:uppercase;
    .wc{text-transform:none; font-size:16px; line-height:16px; letter-spacing:.8px; display:block; font-weight:300; padding:0 0 20px; color:#fff;}
  }
  h1, p{color:#FFF; clear:both;}
  p{
    font-size:16px; line-height:24px; padding:25px 0 0; font-weight:300; letter-spacing:0.12px;
    br{display:none;}
  }
}
/*Banner :End*/

/* Panel Right Content :Begin*/
.bg{
  &1{background:url(../images/img-p2w-ams.jpg) 0 0 / cover no-repeat;} 
  &2{background:url(../images/img-p2w-cis.jpg) 0 0 / cover no-repeat;} 
  &3{background:url(../images/img-p2w-ad-project.jpg) 0 0 / cover no-repeat;} 
  &4{background:url(../images/img-p2w-lite.jpg) 0 0 / cover no-repeat;}
  &5{background:url(../images/img-p2w-lite-pcp-module.jpg) 0 0 / cover no-repeat;}
}
.list-segment{
  text-align:center;
  padding:0 0 50px;
  li{
    display:inline-block;
    position:relative;
    width:100%;
    margin:51px 0 0;
    padding:0 0 200px;
    &.pointer{
      cursor:pointer;
    }
  }
  &-{
    &img, &txt{
      box-shadow:0 5px 7px #999;
    }
    &img{
      float:left; width:100%; height:220px;
      img{z-index:1; display:none;}
    }
    &txt{
      position: absolute;
      text-align: center;
      width: calc(100% - 30px);
      left: 0;
      top: 185px;
      z-index: 2;
      background: #FFF;
      padding: 20px 10px 15px;
      margin: 0 15px;
      h4, p{color:#15011D;}
      h4, p, button{text-align:center;}
      h4{font-size:16px; line-height:16px; font-weight:500;}
      p{font-size:14px; line-height:24px; font-weight:400; padding:20px 2px 0; min-height:113px;}
      button{font-size:18px; line-height:18px; font-weight:500; padding:10px 20px; border-radius:50px;background:#FFF; color:#2B0A3D; border:1px solid #2B0A3D;}
    }
  }
}
.icon-arrow{width:23px; height:14px; display:inline-block; background:url(../images/icon-arrrow.png) #FFF 0 0 /100%  100% no-repeat; margin:1px 0 -1px 10px;}

.partition{
  padding:25px;
  &-{
    &holder{text-align:center; overflow:hidden;}
    &box{
      width:100%;
      position:relative;
      float:left;
      margin:25px 0 0;
      box-shadow:0 5px 7px #999;
      background:#FFF;
      &:first-child{margin:0;}
    }
    &img{
      z-index:1;
      height:255px;
      img{height:100%;}
    }
    &img, &img img{float:left; width:100%;}
    &hold{
      float:left; width:100%; z-index:2; bottom:0;
    }
    &txt{
      padding:25px 30px 25px; overflow:hidden;
      p{text-align:left; font-size:16px; line-height:22px; font-weight:400; color:#15011D;}
    }
  }
}

/* Footer */
.price-deal{
  background:#2B0A3D; padding:30px 25px 50px; margin:0; text-align:center;
  h3{
    font-size:24px; line-height:36px; letter-spacing:1.5px; font-weight:300; color:#FFF;
    span{font-size:24px; line-height:30px;}
  }
  ul{
    li{
      color:#FFF; display:inline-block; margin:50px 0 0; width:100%;
      i, span{clear:both; width:100%; text-align:center;}
      i{background:#68D1F3; border-radius:5vh; width:48px; height:8px; margin:10px 4px; display:inline-block;}
      span{display:block;}
    }
  }
}
.deal-{
  &count{font-size:72px; line-height:72px; font-weight:500;}
  &zone{font-size:18px; line-height:18px; font-weight:300;}
}
.footer-{
  &content{padding:2px 24px;}
  &row{
    clear:both; padding:14px 0; overflow:hidden;
    p, nav{
      font-size:14px; line-height:22px; color:#000; font-weight:400; padding:20px 0 0; text-align:center; clear:both; width:100%;
      a{
        color:#000; font-weight:400;
        &:hover{color:#555;}
      }
    }
    p{
      &:first-child{padding:0;}
    }
    nav{
      a{
        text-decoration:none; margin:0 0 0 30px;
        &:first-child{margin:0;}
      }
    }
  }
}


/* --- CIS --- */
.cis-wrapper{
  box-sizing:border-box;
  .icon-download{width:17px; height:17px; background:url(../images/icon-download.png) 0 0 / cover no-repeat; display:inline-block; margin:-3px 0 -3px;}
  #header, #banner, #platform, #partition, #tools, #sources-update, .footer-content{padding-left:15px; padding-right:15px;}

  /*Heading*/
  .heading{
    h4{text-align:left; font-size:16px; line-height:16px; font-weight:400; margin:24px 0 0;}
    h6{
      font-size:16px; line-height:22px; font-weight:400; position:relative; padding:0 0 11px;
      &:before{content:""; position:absolute; left:2px; bottom:0; width:40px; height:4px; border-radius:2px; background:#12ABDB;}
    }
  }
  
  /*Header*/
  #header{padding-top:18px; padding-bottom:11px;}
  .nav-bar-header{float:right;}
  .nav-bar-header .nav-bar-toggler{width:54px; height:54px; background:url(../images/nav-bar-toggler.png) no-repeat center center; background-size:38px 24px; float:left;}
  .nav-bar-header + .p2w{clear:none; width:128px; height:50px; margin:0;}
  .cis-price-benchmark{font-weight:400; font-size:24px; color:#000; text-decoration:none; float:left; margin:15px 0 0;}
  
  /*Banner*/
  #banner{height:535px; position:relative; border-bottom-width:135px;}
  .jumbotron{
    float:right; /*max-width:none;*/ width:675px; height:280px; padding:35px 30px 30px 32px; margin:185px 0 0;
    h1{
      font-size:24px; line-height:36px;
      // .wc{font-size:10px; line-height:16px; padding: 0 0 12px;}
    }
   // p{padding:22px 0 0; font-size:10px; line-height:16px;}
  }
  #container{background:#FFF;}

  /*Key Outcomes*/
  .list-segment{
    min-height:auto; padding:0;
    li{width:100%; margin:25px 0 0; padding: 0 0 125px;}
    &-{
      &img{
        height:210px; background-color:#0070AD;
      }
      &txt{
        top:180px; height:90px; padding:15px 10px;
        h5{font-size:16px; line-height:28px; font-weight:400; letter-spacing:.015em;}}
    }
  }
  .footer-{
    content{padding-top:5px; padding-bottom:5px;}
    &row{padding:0;}
    .lefter, .righter{width:100%;}
    p, nav{font-size:10px; line-height:16px;}
    p{clear:both; padding:7px 0;}
  }
}
/* Introduction to Price Benchmarking */
.document-downloading{
  clear:both; overflow:hidden; padding:28px 0 14px;
  &-link{width:260px; height:40px; float:left; background:url(../images/bg-downloading.png) 0 0 / cover no-repeat;}
}
.benchmark-process{
  clear:both; overflow:hidden; padding:10px 0 10px;
  p{margin:0 0 6px;}
  p, ul li{font-size:10px; line-height:22px; font-weight:300;}
  ul li{list-style:disc; margin:0 0 6px 20px;}
}
.bg-Keyoutcomes{
  &1{background:url(../images/bg-1-price-per-activity.png) center center / 86px 86px no-repeat;} 
  &2{background:url(../images/bg-2-know-the-market-fte-rates.png) center center / 86px 86px no-repeat;} 
  &3{background:url(../images/bg-3-price-range-for-comparison.png) center center / 86px 86px no-repeat;} 
  &4{background:url(../images/bg-4-price-from-historical-deals.png) center center / 86px 86px no-repeat;}
  &5{background:url(../images/bg-5-comparison-of-internal-price-based-on-cost-and-marketprices.png) center center / 86px 86px no-repeat;}
}
/* Which tool to use? */
#tools{
  background:#F2F2F2; padding:0 0 48px;
  .heading h4{text-align:left; font-size:18px; line-height:18px; font-weight:400; padding:52px 0 51px; margin:0 0;}
}
.tools{
  &-{
    &holder{clear:both; width:100%; float:left;}
    &box{
      float:right; padding:27px 23px; background:#FFF; border:1px solid #C7C7C7; box-shadow:0 0 5px #C7C7C7; width:100%; box-sizing:border-box; /*margin:30px 0 0;*/
      &:first-child{float:left; margin:0 0 30px;}
      .heading h6{
        padding:0 0 46px;
        &:before{bottom:35px;}
        }
         
    }
    &wrap{float:left; width:100%; box-sizing:border-box;}
    &list, &list li, &feature{clear:both; width:100%; float:left; box-sizing:border-box;}
    &list{
      padding:0 25px 0 0;
      li{
        font-size:10px; line-height:16px; font-weight:400; list-style:disc; margin:15px 0 0 17px;
        &:first-child{margin-top:0;}
      }
    }
    &feature{
      padding:0; height:100%;
      li{
        clear:both; float:left; font-size:10px; line-height:22px; font-weight:400; background:#00E6E3; border-radius:36px; margin:30px 0 0; padding:5px 20px 5px 20px;
        &:first-child{margin:0;}
        .icon-download{margin-left:10px;}
      }
    }
  }
}
#sources-update{
  display:flex; box-sizing:border-box; flex-direction:column; padding-top:15px; padding-bottom:15px;
  .panel-{
    &left, &right{box-sizing:border-box; padding:20px; background:#F2F2F2; border:1px solid #C7C7C7; box-shadow:0 0 5px #C7C7C7;}
    &left{/*flex:auto; overflow:hidden;*/ width:100%; clear:both;}
    &right{/*margin:0 0 0 45px; flex:1; float:right; max-width:472px;*/width:100%; clear:both; margin:15px 0 0;}
  }
}
.update-announce{
  display:flex; clear:both; flex-direction:column; width:100%;padding:25px 0;
  marquee{
    height:75px;
    ul{
      li{
        font-size: 10px; line-height:16px; font-weight:400; margin:28px 0 0; color:#2B0A3D; text-decoration:none;
      }
    }
  }
  a{
    flex:auto; clear:both; font-size:10px; line-height:16px; font-weight:400; margin:28px 0 0; color:#2B0A3D; text-decoration:none;
    &:first-child{margin:48px 0 0;}
    &:hover{color:#000}
  }
}
.sourced{
  display:flex; clear:both; flex-direction:row;
  li{
    flex:auto; align-items:center; display:flex; margin:25px 0;
    a{display:inline-block;}
  }
  &-{
    &capgemini{width:150px; height:55px; background:url(../images/capgemini_CIS_UPD.png) center center / cover no-repeat;}
    &wavestone{width:170px; height:70px; background:url(../images/Hex.png) center center / cover no-repeat;}
    &everest{width:170px; height:50px; background:url(../images/sourced-everest.png) center center / cover no-repeat;}
    &isg{width:70px; height:47px; background:url(../images/sourced-isg.png) center center / cover no-repeat;}
  }
}


@media (min-width:320px){
  /* body{border-top:10px solid #ff6200;} */
  /* --- P2W --- */


  /* --- CIS --- */
  .cis-wrapper .list-segment-txt h5{font-size:20px; line-height:28px;} 
}

@media (min-width:480px){
  /* body{border-top:10px solid #1eff00;} */

  /* --- P2W --- */
  .jumbotron{margin:100px 0 0 0;}
  #partition{padding:35px;}
  .partition-box{margin:35px 0 0;}
  .partition-box:first-child{margin:0;}

  /* --- CIS --- */
  .cis-wrapper .jumbotron h1{font-size:40px; line-height:42px;}
}

@media (min-width:576px){
  /* body{border-top:10px solid #ff0000;} */
  
  /* --- P2W --- */
  .jumbotron{margin:140px 0 0 0;}
  .jumbotron p br{display:block;}
  #platform {padding:0 15px;}
  .heading h3{text-indent:15px;}
  .list-segment li{width:235px; margin:51px 10px 0;}
  #partition{padding:40px;}
  .partition-box{margin:40px 0 0;}
  .partition-box:first-child{margin:0;}

  /* --- CIS --- */
  .cis-wrapper #header, .cis-wrapper #banner, #platform{padding-left:15px; padding-right:15px;}
  .cis-wrapper .nav-bar-header{float:left;}
  .cis-wrapper .nav-bar-header + .p2w{margin:0 0 0 38px;}
  .cis-wrapper .cis-price-benchmark{margin:15px 0 0 48px;}
  .cis-wrapper .list-segment li{width:45%; margin:25px 2% 0; padding:0 0 110px;}
  .cis-wrapper .list-segment-txt h5{font-size:16px; line-height:22px;}

}

@media (min-width:680px){
  /* body{border-top:10px solid #044951;} */
  
  /* --- P2W --- */
  .list-segment li{width:290px;}
  .partition-txt{padding:25px 35px;} 
  .price-deal{padding:55px 20px; margin:0;}
  .price-deal h3{font-size:32px; line-height:40px; letter-spacing:1.5px;}
  .price-deal ul{padding:50px 0 0 0;}
  .price-deal ul li{display:inline-block; margin:0 20px; width:auto; text-align:left;}
  .price-deal ul li i, .price-deal ul li span{text-align:left; float:left;}
  .price-deal ul li i{display:block; margin:20px 8px 40px 4px;}
  .price-deal ul li span{display:inline-block; width:auto;}
  .price-deal ul li span.deal-zone{margin-left:4px;}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:768px){
  /* body{border-top:10px solid #00e1ff;} */
  
  /* --- P2W --- */
  #banner{padding:0 48px;}
  .jumbotron{max-width:100%; padding:45px 20px 30px 30px; margin:115px 0 0 0;}
  .heading h3{text-indent:25px;}
  .list-segment li{width:325px;}
  #partition{padding:50px 10px;}
  .partition-holder{width:100%; padding:0 0 15px; display:flex;}
  .partition-box, .partition-box:first-child{margin:0 20px;}
  .partition-box{/*height:390px;*/ flex:auto;}
  .partition-txt{min-height:110px; padding:15px 15px 10px;}
  .partition-txt p{font-size:14px; line-height:18px;}
  .price-deal h3{font-size:22px; line-height:30px;}
  .price-deal ul{padding:35px 0 0 0;}
  .deal-count{font-size:42px; line-height:42px;}
  .price-deal ul li i{width:30px; height:6px; margin:15px 8px 25px 3px;}
  .deal-zone{font-size:14px; line-height:14px;}

  /* --- CIS --- */
  .cis-wrapper #header, .cis-wrapper #banner, .cis-wrapper #platform, .cis-wrapper #partition, .cis-wrapper #tools, .cis-wrapper #sources-update, .cis-wrapper .footer-content{padding-left:20px; padding-right:20px;}
  .cis-wrapper #header{padding-top:18px; padding-bottom:11px;}
  .cis-wrapper .nav-bar-header{margin:-5px 0 2px;}
  .tools-wrap{width:55%;}
  .tools-wrap + .tools-wrap{ border-left:1px solid #6C757D; padding:0 0 0 45px; width:45%;}
 
  
  .cis-wrapper .list-segment{display:flex; margin:0 -10px;}
  .cis-wrapper .list-segment li{flex:auto; width:240px; padding:0 0 150px; margin:51px 10px 0;}
  .cis-wrapper .footer-content{padding-top:5px; padding-bottom:5px;}
  .cis-wrapper .list-segment-txt{top:105px; height:105px; padding:15px 5%;}
  .cis-wrapper .list-segment-txt h5{font-size:15px; line-height:20px;}
  .cis-wrapper .list-segment-img{width:100%; height:125px; background-size:55px 55px;}
}

/* Large devices (desktops, 992px and up) */
@media (min-width:992px){
  /* body{border-top:10px solid #f6ff00;} */
  
  /* --- P2W --- */
  .jumbotron{max-width:675px; min-height:280px; margin:55px 0 0 230px;}
  #platform{padding:0 10px;}
  .heading h3{text-indent:15px;}
  .list-segment li{width:310px; margin:51px 10px 0;}
  .partition-box{}
  .partition-txt{padding:15px 20px 15px; min-height:105px;}
  .partition-txt p{font-size:16px; line-height:20px;}
  .footer-content{padding:2px 25px;}
  .footer-row{padding:14px 0;}
  .footer-row p, .footer-row nav{font-size:14px; line-height:24px; padding:0; text-align:left; clear:none; width:auto;}
  .price-deal{padding:55px 48px; margin:0;}
  .price-deal ul li{margin:0 60px;}

  /* --- CIS --- */
  .tools-box{width:calc(50% - 15px);}
  .tools-box:first-child{margin:0;}
  .cis-wrapper .jumbotron{margin:185px 0 0 270px; float:left;}
  .cis-wrapper .footer-content{padding-top:5px; padding-bottom:5px;}
  .cis-wrapper .footer-row nav{margin:25px 0 0;}
  .cis-wrapper .footer-row .lefter, .cis-wrapper .footer-row .righter{width:auto;}
  #sources-update{flex-direction:row;}
  #sources-update .panel-right{margin:0 0 0 15px;}
}
@media (min-width:1024px){
  /* body{border-top:10px solid #00ff33;} */

  /* --- P2W --- */
  

  /* --- CIS --- */
  .cis-wrapper #header, .cis-wrapper #banner, .cis-wrapper #platform, .cis-wrapper #partition, .cis-wrapper #tools, .cis-wrapper #sources-update, .cis-wrapper .footer-content{padding-left:30px; padding-right:30px;}
  .cis-wrapper .jumbotron{}
  .cis-wrapper .list-segment{display:flex; margin:0 -10px;}
  .cis-wrapper .list-segment li{flex:auto; margin:51px 10px 0;}
  .cis-wrapper .footer-content{padding-top:5px; padding-bottom:5px;}
  .cis-wrapper .list-segment-txt{top:140px; height:90px; padding:15px 5%;}
  .cis-wrapper .list-segment-txt h5{font-size:16px; line-height:22px;}
  .cis-wrapper .list-segment-img{width:100%; height:170px; background-size:65px 65px;}
}

@media (min-width:1080px){
    /* body{border-top:10px solid #02fccf;} */
    /* 175% */

    /* --- P2W --- */
    #header{padding:15px 30px 10px;}
    #header .capgemini{width:120px; height:25px;}
    #banner{border-width:70px; height:330px; padding:0 30px;}
    .p2w{width:150px; height:50px;}
    .jumbotron{max-width:440px; min-height:160px; padding:22px 0 0 18px; margin:60px 0 0 200px;}
    .jumbotron h1 .wc{font-size:11px; line-height:11px;}
    .jumbotron h1{font-size:22px; line-height:28px;}
    .jumbotron p{font-size:12px; line-height:18px;}
    .heading h3{font-size:16px; line-height:16px; padding:30px 0 0; text-indent:20px;}
    .list-segment{padding:0 0 30px;}
    .list-segment li {width:185px; margin:30px 7px 0; padding:0 0 135px;}
    .list-segment-img{/*height:140px;*/}
    .list-segment-txt{/*top:115px; height:115px;*/}
    .list-segment-txt h4{font-size:12px; line-height:12px;}
    .list-segment-txt p{font-size:12px; line-height:14px; padding:7px 2px 0; min-height:100px;}
    .list-segment-txt button{font-size:12px; line-height:12px; padding:4px 12px 5px;}
    .list-segment-txt button .icon-arrow{width:14px; height:8px; margin:1px 0 -1px 10px;}
    #partition{padding:25px 15px;}
    .partition-txt{min-height:55px;}
    .partition-txt p{font-size:11px; line-height:18px;}
    .price-deal{padding:30px 38px}
    .price-deal h3{font-size:16px; line-height:24px;}
    .price-deal h3 span{font-size:11px; line-height:16px;}
    .price-deal ul{padding:30px 0 0 0;}
    .deal-count{font-size:30px; line-height:30px;}
    .price-deal ul li{margin:0 30px;}
    .price-deal ul li i{width:24px; height:5px; margin:12px 8px 20px 3px;}
    .deal-zone{font-size:12px; line-height:12px;}
    .footer-row{padding:5px 0;}
    .footer-row p, .footer-row nav{font-size:11px; line-height:16px;}

    /* --- CIS --- */
    .cis-wrapper .heading h4{font-size:20px; line-height:20px; margin:28px 0 0;}
    .document-downloading-link{width:129px; height:20px;}
    #tools .heading h4{font-size:20px; line-height:20px; padding:22px 0 21px;}
    .cis-wrapper .heading h6{font-size:14px; line-height:22px;}
    .tools-wrap + .tools-wrap{padding:0 0 0 25px;}
    .cis-wrapper .jumbotron{margin:185px 0 0 310px; max-width:none;}
    .cis-wrapper .jumbotron h1{font-size:24px; line-height:30px;} 
    .cis-wrapper .jumbotron h1 .wc{font-size:11px; line-height:18px;}
    .cis-wrapper .jumbotron p{font-size:11px; line-height:18px;}
    .benchmark-process p, .benchmark-process ul li{font-size:10px; line-height:24px;}
    .tools-list li{font-size:10px; line-height:18px;}
    .tools-feature li{font-size:10px; line-height:16px; margin:36px 0 0;}
    .cis-wrapper .list-segment-txt h5{font-size:12px; line-height:18px;}
    .sourced li{margin:30px 0;}
    // .sourced-capgemini{width:90px; height:25px;}
    // .sourced-wavestone{width:75px; height:35px;}
    // .sourced-everest{width:85px; height:25px;}
    // .sourced-isg{width:38px; height:25px;}
    .sourced-capgemini{width:135px; height:55px;}
    .sourced-wavestone{width:110px; height:60px; }
    .sourced-everest{width:130px; height:35px;}
    .sourced-isg{width:45px; height:30px;}
    #sources-update{padding:25px 30px;}
    #sources-update .panel-left, #sources-update .panel-right{padding:25px 27px;}
    .update-announce{width:400px;}
    .update-announce a{font-size:11px; line-height:18px; margin:18px 0 0;}
    .update-announce a:first-child{margin:24px 0 0;}
    .update-announce marquee{
      ul{
        li{
          font-size: 11px; line-height:18px; margin:18px 0 0; 
        }
      }
    }
    .cis-wrapper .footer-row p, .cis-wrapper .footer-row nav{font-size:11px; line-height:18px;}
    #sources-update .panel-left{flex:auto; overflow:hidden;}
    #sources-update .panel-right{margin:0 0 0 35px; flex:1; float:right; max-width:772px;}
}

/* X-Large devices (large desktops, 1200px and up) */

@media (min-width:1200px){
  /* body{border-top:10px solid #2f00ff;} */


  /* --- P2W --- */
  .jumbotron{margin:62px 0 0 312px;}
  #platform {padding:0 15px;}
  .heading h3{text-indent:50px;}
  .list-segment li{width:325px; margin:51px 20px 0;}
  #partition{padding:50px 24px;}
  .partition-txt{min-height:95px; padding:20px 25px 20px;}
  .partition-txt p{font-size:16px; line-height:22px;}
  .footer-content{padding:2px 25px;}
  .footer-row p, .footer-row nav{font-size:16px;}
}

@media (min-width:1280px){
  /* body{border-top:10px solid #036f1c;} */
  /* 150% */

  /* --- P2W --- */
  #header{padding:18px 36px 11px;}
  #header .capgemini{width:140px; height:30px;}
  #banner{border-width:90px; height:420px; padding:0 36px;}
  .p2w{width:180px; height:70px;}
  .jumbotron{max-width:550px; min-height:215px; padding:42px 0 0 30px; margin:60px 0 0 250px;}
  .jumbotron h1 .wc{font-size:13px; line-height:13px;}
  .jumbotron h1{font-size:26px; line-height:32px;}
  .jumbotron p{font-size:14px; line-height:20px;}
  .heading h3{font-size:18px; line-height:18px; padding:35px 0 0; text-indent:20px;}
  .list-segment{padding:0 0 35px;}
  .list-segment li {width:220px; margin:35px 11px 0; padding:0 0 160px;}
  .list-segment-img{/*height:140px;*/}
  .list-segment-txt{/*top:115px; height:140px;*/}
  .list-segment-txt h4{font-size:14px; line-height:14px;}
  .list-segment-txt p{font-size:12px; line-height:16px; padding:10px 2px 0; min-height:90px;}
  .list-segment-txt button{font-size:13px; line-height:13px; padding:6px 15px;}
  #partition{padding:35px 20px;}
  .partition-txt{min-height:70px;}
  .partition-txt p{font-size:12px; line-height:22px;}
  .price-deal{padding:35px 38px}
  .price-deal h3{font-size:20px; line-height:26px;}
  .price-deal h3 span{font-size:13px; line-height:16px;}
  .price-deal ul{padding:35px 0 0 0;}
  .deal-count{font-size:36px; line-height:36px;}
  .price-deal ul li{margin:0 45px;}
  .price-deal ul li i{width:30px; height:6px; margin:15px 8px 25px 3px;}
  .deal-zone{font-size:14px; line-height:14px;}
  .footer-row{padding:7px 0;}
  .footer-row p, .footer-row nav{font-size:12px; line-height:18px;}

  /* --- CIS --- */
  .cis-wrapper .heading h4{font-size:24px; line-height:24px; margin:40px 0 0;}
  .document-downloading-link{width:155px; height:24px;}
  #tools .heading h4{font-size:24px; line-height:24px; padding:32px 0 31px;}
  .cis-wrapper .heading h6{font-size:16px; line-height:24px;}
  .tools-wrap + .tools-wrap{padding:0 0 0 35px;}
  .cis-wrapper .jumbotron{/*width:500px*/ max-width:none;}
  .cis-wrapper .jumbotron h1{font-size:28px; line-height:36px;} 
  .cis-wrapper .jumbotron h1 .wc{font-size:12px; line-height:20px;}
  .cis-wrapper .jumbotron p{font-size:12px; line-height:20px;}
  .benchmark-process p, .benchmark-process ul li{font-size:11px; line-height:28px;}
  .tools-list li{font-size:11px; line-height:20px;}
  .tools-feature li{font-size:11px; line-height:20px; margin:40px 0 0;}
  .cis-wrapper .list-segment-txt h5{/*font-size:16px; line-height:22px;*/}
  .sourced li{margin:35px 0;}
  // .sourced-capgemini{width:110px; height:30px;}
  // .sourced-wavestone{width:95px; height:40px;}
  // .sourced-everest{width:100px; height:30px;}
  // .sourced-isg{width:45px; height:30px;}

  .sourced-capgemini{width:150px; height:60px;}
  .sourced-wavestone{width:125px; height:60px; }
  .sourced-everest{width:150px; height:45px;}
  .sourced-isg{width:60px; height:40px;}

  #sources-update{padding:30px 35px;}
  #sources-update .panel-left, #sources-update .panel-right{padding:30px 32px;}
  .update-announce{width:425px;}
  .update-announce a{font-size:12px; line-height:20px; margin:18px 0 0;}
  .update-announce a:first-child{margin:24px 0 0;}
  .update-announce marquee{
    ul{
      li{
        font-size: 12px; line-height:20px; margin:18px 0 0; 
      }
    }
  }
  .cis-wrapper .footer-row p, .cis-wrapper .footer-row nav{font-size:12px; line-height:20px;}
  .cis-wrapper #header, .cis-wrapper #banner, .cis-wrapper #platform, .cis-wrapper #partition, .cis-wrapper #tools, .cis-wrapper #sources-update, .cis-wrapper .footer-content{padding-left:45px; padding-right:45px;}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width:1400px){
  /* body{border-top:10px solid #ff00e1;} */

  /* --- P2W --- */
  #header .capgemini{width:180px; height:45px;}
  #platform {padding:0 16px;}
  #banner{border-width:110px; height:510px; padding:0 42px;}
  .p2w{width:235px; height:90px;}
  .jumbotron{max-width:675px; min-height:280px; padding:42px 0 0 30px; margin:60px 0 0 225px;}
  .jumbotron h1 .wc{font-size:16px; line-height:16px;}
  .jumbotron h1{font-size:40px; line-height:48px;}
  .jumbotron p{font-size:16px; line-height:24px;}
  .heading h3{font-size:24px; line-height:24px; text-indent:25px;}
  .list-segment li{width:245px; margin:51px 10px 0;}
  .list-segment-img{/*height:140px;*/}
  .list-segment-txt{height:190px;}
  .list-segment-txt h4{font-size:16px; line-height:16px;}
  .list-segment-txt p{font-size:14px; line-height:18px; min-height:100px;}
  .list-segment-txt button{font-size:16px; line-height:16px;}
  .partition-txt {min-height:95px;}
  .partition-txt p{font-size:16px; line-height:24px;}
  .footer-content{padding:2px 48px;}
  .footer-row p{font-size:17px;}
  .price-deal h3{font-size:32px; line-height:34px;}
  .price-deal h3 span{font-size:24px; line-height:26px;}
  .deal-count{font-size:72px; line-height:72px;}
  .deal-zone{font-size:18px; line-height:20px;}
  .footer-row p, .footer-row nav{font-size:16px; line-height:24px;}



  /* --- CIS --- */
  .cis-wrapper .heading h4{font-size:28px; line-height:28px; margin:46px 0 0;}
  .document-downloading-link{width:195px; height:30px;}
  #tools .heading h4{font-size:28px; line-height:28px; padding:42px 0 41px;}
  .cis-wrapper .heading h6{font-size:18px; line-height:26px;}
  .tools-wrap + .tools-wrap{padding:0 0 0 40px;}
  .cis-wrapper .jumbotron{/*width:555px*/}
  .cis-wrapper .jumbotron h1{font-size:36px; line-height:44px;} 
  .cis-wrapper .jumbotron h1 .wc{font-size:14px; line-height:22px;}
  .cis-wrapper .jumbotron p{font-size:14px; line-height:22px;}
  .benchmark-process p, .benchmark-process ul li{font-size:13px; line-height:30px;}
  .tools-list li{font-size:13px; line-height:22px;}
  .tools-feature li{font-size:13px; line-height:24px; margin:44px 0 0;}
  .update-announce{width:500px;}
  .update-announce a{font-size:14px; line-height:22px; margin:30px 0 0;}
  .update-announce a:first-child{margin:36px 0 0;}
  .update-announce marquee{
    ul{
      li{
        font-size: 14px; line-height:22px; margin:30px 0 0; 
      }
    }
  }
  .cis-wrapper .footer-row p, .cis-wrapper .footer-row nav{font-size:14px; line-height:22px;}
  .cis-wrapper #tools{padding:0 45px 48px;}
  #sources-update{padding:35px 40px;}
  #sources-update .panel-left, #sources-update .panel-right{padding:35px 37px;}
  .sourced li:first-child{justify-content:flex-start;}
  // .sourced-capgemini{width:125px; height:35px;}
  // .sourced-wavestone{width:125px; height:50px;}
  // .sourced-everest{width:125px; height:35px;}
  // .sourced-isg{width:52px; height:35px;}


  .sourced-capgemini{width:210px; height:75px;}
    .sourced-wavestone{width:175px; height:75px; }
    .sourced-everest{width:185px; height:55px;}
    .sourced-isg{width:75px; height:50px;}
  .tools-box{width:48.5%; margin:0;}
  .cis-wrapper .list-segment{margin:0 -10px;}
  .cis-wrapper .list-segment li{padding:0 0 120px; margin:51px 11px 0;}
  .cis-wrapper .list-segment-txt{top:180px; height:100px;}
  .cis-wrapper .list-segment-txt h5{font-size:17px; line-height:24px;}
  .cis-wrapper .list-segment-img{width:100%; height: 210px; background-size:86px 86px;}
}
@media (min-width:1440px){
  /* body{border-top:10px solid #800765;} */
  /* 125% */

  /* --- P2W --- */
  #header{padding:18px 42px 11px;}
  #header .capgemini{width:180px; height:45px;}
  #banner{border-width:110px; height:510px; padding:0 42px;}
  .p2w{width:235px; height:90px;}
  .jumbotron{max-width:675px; min-height:280px; padding:42px 0 0 30px; margin:60px 0 0 225px;}
  .jumbotron h1 .wc{font-size:16px; line-height:16px;}
  .jumbotron h1{font-size:40px; line-height:48px;}
  .jumbotron p{font-size:16px; line-height:24px;}
  .heading h3{font-size:20px; line-height:20px; padding:45px 0 0; text-indent:10px;}
  .list-segment{padding:0 0 35px;}
  .list-segment li {width:250px; margin:35px 10px 0; padding:0 0 160px;}
  .list-segment-img{/*height:140px;*/}
  .list-segment-txt{/*top:115px; height:140px;*/}
  .list-segment-txt h4{font-size:14px; line-height:14px;}
  .list-segment-txt p{font-size:13px; line-height:16px; padding:10px 2px 0; min-height:90px;}
  .list-segment-txt button{font-size:14px; line-height:14px; padding:8px 16px;}
  #partition{padding:45px 20px;}
  .partition-txt{min-height:70px;}
  .partition-txt p{font-size:14px; line-height:24px;}
  .price-deal{padding:45px 38px}
  .price-deal h3{font-size:24px; line-height:30px;}
  .price-deal h3 span{font-size:15px; line-height:16px;}
  .price-deal ul{padding:45px 0 0 0;}
  .deal-count{font-size:42px; line-height:42px;}
  .price-deal ul li{margin:0 52px;}
  .price-deal ul li i{width:30px; height:6px; margin:15px 8px 25px 3px;}
  .deal-zone{font-size:16px; line-height:18px;}
  .footer-row{padding:9px 0;}
  .footer-row p, .footer-row nav{font-size:14px; line-height:20px;}
}

@media (min-width:1600px){
  /* body{border-top:10px solid #00ffb3;} */

  /* --- P2W --- */
  #header .capgemini{width:180px; height:45px;}
  #platform {padding:0 16px;}
  #banner{border-width:110px; height:510px; padding:0 42px;}
  .p2w{width:235px; height:90px;}
  .jumbotron{max-width:675px; min-height:280px; padding:42px 0 0 30px; margin:60px 0 0 225px;}
  .jumbotron h1 .wc{font-size:16px; line-height:16px;}
  .jumbotron h1{font-size:40px; line-height:48px;}
  .jumbotron p{font-size:16px; line-height:24px;}
  .heading h3{font-size:24px; line-height:24px; text-indent:15px;}
  .list-segment li{width:274px; margin:51px 16px 0;}
  .list-segment-img{/*height:140px;*/}
  .list-segment-txt{height:190px;}
  .list-segment-txt h4{font-size:16px; line-height:16px;}
  .list-segment-txt p{font-size:14px; line-height:18px; min-height:100px;}
  .list-segment-txt button{font-size:16px; line-height:16px;}
  .partition-txt p{font-size:16px; line-height:24px;}
  .price-deal h3{font-size:32px; line-height:34px;}
  .price-deal h3 span{font-size:24px; line-height:26px;}
  .deal-count{font-size:72px; line-height:72px;}
  .deal-zone{font-size:18px; line-height:20px;}
  .footer-row p, .footer-row nav{font-size:16px; line-height:24px;}


  /* --- CIS --- */
  .cis-wrapper .heading h4{font-size:32px; line-height:32px; margin:52px 0 0;}
  #tools .heading h4{font-size:32px; line-height:32px; padding:52px 0 51px;}
  .cis-wrapper .heading h6{font-size:24px; line-height:32px;}
  .cis-wrapper .jumbotron h1{font-size:40px; line-height:48px;} 
  .cis-wrapper .jumbotron h1 .wc{font-size:16px; line-height:24px;}
  .cis-wrapper .jumbotron p{font-size:16px; line-height:24px;}
  .benchmark-process p, .benchmark-process ul li{font-size:16px; line-height:32px;}
  .tools-list li{font-size:16px; line-height:24px;}
  .tools-feature li{font-size:16px; line-height:32px; margin:48px 0 0;}
  .update-announce{width:550px;}
  .update-announce a{font-size:16px; line-height:24px; margin:43px 0 0;}
  .update-announce marquee{
    ul{
      li{
        font-size: 16px; line-height:24px; margin:48px 0 0; 
      }
    }
  }
  #sources-update{padding:40px 45px;}
  #sources-update .panel-left, #sources-update .panel-right{padding:50px 48px;}
  .cis-wrapper .footer-row p, .cis-wrapper .footer-row nav{font-size:16px; line-height:24px;}
  .cis-wrapper .list-segment{display:flex; margin:0 -18px;}
  .cis-wrapper .list-segment li{padding:0 0 150px; margin:51px 21px 0;}
  .cis-wrapper .list-segment-txt{height:115px;}
  .cis-wrapper .list-segment-txt h5{font-size:21px; line-height:28px;}
  .sourced-capgemini{width:225px; height:80px;}
  .sourced-wavestone{width:210px; height:95px;}
  .sourced-everest{width:290px; height:83px;}
  .sourced-isg{width:98px; height:66px;}
}
@media (min-width:1800px){
    /* body{border-top:10px solid #1a0458;} */

    /* --- P2W --- */
    #platform {padding:0 20px;}
    .list-segment li{width:305px; margin:51px 20px 0;}
}
@media (min-width:1920px){
    /* body{border-top:10px solid #1a0458;} */

    /* --- P2W --- */
    #platform {padding:0 24px;}
    .list-segment li{width:325px; margin:51px 20px 0;}
    #partition{padding:50px 24px;}
    .partition-box{height:390px; width:30.7%;}
}
.buttonbgcolorcis{
  color: black;

}

.cistextaligncenter{
  
    line-height: 44px;
}

.offcanvas-backdrop.show {
  opacity: -1.5;
}



@color_1: #2b0a3d;
@background_color_1: transparent;
@border_color_1: none;

.faq-accordion {
	.ngbAccordionHeader {
		button {
			font-weight: 200;
			color: @color_1;
			background-color: @background_color_1;
			text-decoration: none !important;
			font-size: 16px;
			font-weight: bold;
			padding-left: 25px;
		}
	}
}
.collapse.show {
	border-top: 2px solid #2b0a3d;
}
.accordion-button {
	&:focus {
		z-index: 3;
		border-color: @border_color_1;
		outline: 0;
		box-shadow: none;
	}
}

.left-padding{
  padding-left: 20px;
}

/*
.list-segment-txt {
  position: absolute;
  text-align: center;
  width: 80%;
  height: 180px;
  left: 14px;
  top: 185px;
  z-index: 2;
  background: #FFF;
  padding: 20px 5% 15px;
  margin: 0 5%;
}

.list-segment-img {
  float: left;
  width: 100%;
  height: 100%;
  height: 220px;
}*/